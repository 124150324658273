.deliveryPrediction {
  color: var(--mainColor);
  background-color: #e6e9ea;
  font-weight: 500;
}

.orderBubbleStatus {
  border-radius: 8px;
  padding-block: 0.2rem;
  padding-inline: 0.8rem;
  color: #f0f0f0;
  font-weight: 500;
  margin-bottom: 3px;
}

.bubbleStatusEntregue {
  background-color: #5ccb58;
}

.bubbleStatusem_rota {
  background-color: #e8ae6d;
}

.bubbleStatusCancelado {
  background-color: #f15555;
}

.bubbleStatusAguardando {
  background-color: #3f8acb;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  text-align: center;
}

.bubbleStatusAtendido {
  background-color: #3f8acb;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  text-align: center;
}

.canceled {
  background-color: #f22222;
  border-radius: 50%;
  padding: 2rem;
  z-index: 1;
  position: absolute;
  margin: 2rem;
}

.waiting {
  background-color: #c0dce2;
  border-radius: 50%;
  padding: 2rem;
  z-index: 1;
  position: absolute;
  margin: 2rem;
}

.approved {
  background-color: #c0cdd5;
  border-radius: 50%;
  padding: 2rem;
  z-index: 1;
  position: absolute;
  margin: 2rem;
}

.faded {
  background-color: #e7e7e7;
  border-radius: 50%;
  padding: 2rem;
  z-index: 1;
  position: absolute;
  margin: 2rem;
}

.rastreioStepOne {
  margin-bottom: 5rem;
}

.rastreioStep {
  margin-bottom: 4.5rem;
}

.icon {
  font-size: 2rem;
  color: var(--mainColor);
  margin-inline: 3rem;
  z-index: 2;
}

.iconFaded {
  font-size: 2rem;
  color: #c2c2c2;
  margin-inline: 3rem;
  z-index: 2;
}


.rastreioLine {
  position: fixed;
  border-bottom: 2px dotted #033858;
  transform: rotate(0.25turn);
  width: 7rem;
  z-index: 0;
  margin-inline: 0.5rem;
  margin-top: -1rem;
}

.rastreioLineFaded {
  position: fixed;
  border-bottom: 2px dotted #868686;
  transform: rotate(0.25turn);
  width: 7rem;
  z-index: 0;
  margin-inline: 0.5rem;
  margin-top: -1rem;
}
