  .eyeIcon{
    position: absolute;
    z-index: 2;
    right: 12px;
    top: 7px;
  }

  .button{
    margin-top: 1rem;
    background-color: #53bf9d;
    border: none;
    border-radius: 8px;
    color: #f0f0f0;
    font-weight: 500;
    font-size: 1.1rem;
    padding-block: 0.3rem;
    padding-inline: 1.2rem;
  }
  
  .checklist li{
    display: flex;
    align-items: center;
  }