.productCard{
    display: flex;
    flex-direction: column;
    margin-block: 0.2rem 0.8rem;
    border: 1px solid #C0CDD5;
    border-radius: 8px;
}

.imgStyle{
    width: 80px;
}

.productDescription{
    font-weight: 500; 
    font-size: 1.1rem;
}
.productDescription span{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.boxBadge{
    font-size: 0.8rem;
    font-weight: 500;
    background-color: #E7E7E7;
    border-radius: 4px;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0.1rem 0.5rem 0.15rem 0.5rem;
}

.boxBadgeList{
    font-size: 0.8rem;
    font-weight: 500;
    width: fit-content;
    display: flex;
    align-items: center;
    filter: invert(70%) sepia(0%) saturate(131%) hue-rotate(150deg) brightness(77%) contrast(78%);
}

.alertIcon{
    width: 14px;
    height: 14px;
    filter: invert(0%) sepia(89%) saturate(14%) hue-rotate(100deg) brightness(89%) contrast(100%);
    margin-right: 0.2rem;
}

.trashCan img{
    filter: invert(81%) sepia(27%) saturate(96%) hue-rotate(159deg) brightness(140%) contrast(55%);
    height: 20px;
}

.counterBar{
    border-top: 1px solid #C0CDD5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    border-radius: 0 0 8px 8px;
}

.originalPrice{
    text-decoration: line-through;
    color: #C2C2C2;
    font-weight: 500;
    padding-right: 0.4rem;
}

.counterBarList{
    border-top: 1px solid #C0CDD5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    border-radius: 0 0 8px 8px;
}
