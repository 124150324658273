.topTags{
  display: flex;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.clipboardIcon {
  right: 15%;
}

.itemCardAdditionDesktop {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 16px;
  width: 220px;
  margin-bottom: 0.5rem;
  z-index: 2;
  height: 38px;
}

.favoriteIcon{
  position: absolute;
  right: 40px;
}

.favoriteIcon div img{
  width: 22px !important;
  right: 0px !important;
  top: 0px !important;
}

.barCounter {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--mainColor);
  border: 1px solid #033858;
  border-radius: 4px;
  background-color: #e6e9ea;
  height: 24px;
  width: 4rem;
  background-position: center;
  text-align: center;
  display: block;
}

.addToCart {
  font-size: 0.9rem;
  font-weight: 500;
  display: block;
  padding: 0.2rem;
}

.productTitle {
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.selection {
  font-weight: 300;
  font-size: 0.9rem;
}

.comparisonTable {
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid #C0CDD5;
  border-radius: 8px;
}

.comparisonTableTitle {
  font-weight: 500;
  font-size: 1.1rem;
  margin-block: 1rem;
}

.comparisonTableContent div {
  margin-block: 0.4rem;
}

.comparisonTableFirstElement {
  font-weight: 500;
  font-size: 1.1rem;
  margin-top: 1rem;
}

.itemCardAddition{
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
  width: 100%;
}

.carousel{
  height: 230px;
}

.carousel:has(.slide img) {
  width: auto !important;
}

.carousel img {
  width: auto !important;
  height: 230px;
}

.carousel .thumbs-wrapper {
  margin: 0px !important;
}

.sugestedProducts{
  margin-bottom: 120px;
}

.errorImage{
  margin-block: 1rem;
  max-width: 280px;
}