.modalContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 5px 5px 20px #02283f40;
}

.loadingContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 155px;
  min-width: 287px;
}

.title{
  display: flex; 
  justify-content: space-between;
  font-weight: 600;
  color: #033858;
  font-size: 1.4rem;
  width: 100%;
}

.title img{
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
  brightness(100%) contrast(98%);
}

.newList{
  display: flex;
  flex-direction: column;
  padding-block: 3rem;
  width: 80%;
}

.newList input{
  box-shadow: 0px 2px 1px #819CAC;
  width: 100%;
  border-radius: 8px;
  padding: 0.5rem;
  border: 1px solid transparent;
}

.wishlistList{
  max-height: 170px;
  overflow: overlay;
}

.check{
  display: flex; 
  justify-content: space-between;
  padding: 0.3rem 0.5rem;
  border-bottom: 1px solid #C0CDD5;
}

input:checked {
  accent-color: #033858;
}

input:focus{
  accent-color: #033858;
}

.uncheckedListName{
  color: #033858;
  font-weight: 500;
}

.checkedListName {
  color: #033858;
  font-weight: 600;
}

.check .listName:after {
  left: 8px;
  bottom: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.buttonNewList{
  border: none;
  border-radius: 8px;
  background-color: #033858;
  color: #f0f0f0;
  font-weight: 500;
  padding-block: 0.3rem;
  padding-inline: 1.2rem;
  margin-top: 1rem;
}

/* input[type="checkbox"]:checked {
    background-color: red;
    border-color: red;
  } 
*/
