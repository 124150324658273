/* .cardComponentMobile {
    margin-bottom: 110px;
} */

.cardText {
    display: flex;
    justify-content: space-between;
}

.verticalGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.6rem 1rem;
}
