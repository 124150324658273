.emptyValue{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F2F5F6;
    border-radius: 8px;
    padding-block: 1rem;
    margin-top: 2rem;
}

.addButton{
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 59px;
    width: 100%;
    background-color: #53bf9d;
    border-radius: 16px 16px 0 0;
    padding-block: 1rem 1.1rem;
    color: #f0f0f0;
    font-weight: 500;
}

.addNewButton{
    color: #033858;
    background-color: #F2F5F6;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    width: fit-content;
    font-weight: 600;
}

.checkicon{
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(28deg) brightness(190%) contrast(88%);
    transform: translateY(-1px);
    margin-left: 0.5rem;
}

.warningBubble{
    padding: 0.5rem 1rem;
    background-color: #D9E1E6;
    border-radius: 8px;
    color: #033858;
    display: flex;
    justify-content: center;
    width: fit-content;
}

.contactInfo{
    display: flex;
    justify-content: space-between;
    margin-block: 0.375rem;
}

.additionalContactBubble{
    border: 1px solid #C0CDD5;
    background-color: #F2F5F6;
    border-radius: 8px;
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;
}

.editIcon{
    transform: translateY(-2px);
    margin-left: 0.5rem;
    width: 16px;
}