.container{
  padding-inline: 2rem;
  padding-bottom: 1rem;
}

.title{
  display: flex; 
  justify-content: space-between; 
  padding-bottom: 0.5rem;
  font-weight: 600;
  color: #033858;
  font-size: 1.2rem;
}

.title img{
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
  brightness(100%) contrast(98%);
}

.newList{
  display: flex;
  flex-direction: column;
  padding-block: 1rem;
}

.newList input{
  box-shadow: 0px 2px 1px #819CAC;
  width: 100%;
  border-radius: 8px;
  padding: 0.5rem 0.2rem;
  border: 1px solid #819cac99;
}

.check{
  display: flex; 
  justify-content: space-between;
  padding: 0.3rem 0.5rem;
  border-bottom: 1px solid #C0CDD5;
}

input:checked {
  accent-color: #033858;
}

input:focus{
  accent-color: #033858;
}

.uncheckedListName{
  color: #033858;
  font-weight: 500;
}

.checkedListName {
  color: #033858;
  font-weight: 600;
}

.check .listName:after {
  left: 8px;
  bottom: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.newListButton{
  background-color: #033858;
  color: #f0f0f0;
  border-radius: 8px;
  text-align: center;
  padding: 0.5rem 1.5rem;
  width: fit-content;
}

/* input[type="checkbox"]:checked {
    background-color: red;
    border-color: red;
  } 
*/
