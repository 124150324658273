.dropdownAlert {
  position: absolute;
  top: 48px;
  width: 468px;
  right: -290px;
  transform: translateX(-47%);
  background-color: #ffff;
  border-radius: 16px;
  padding: 1rem;
  z-index: 5;
  box-shadow: 1px 6px 10px #54565c6f;
}

.notificationStyle {
  display: flex;
  justify-content: center;
  margin-block: 1.5rem;
  font-size: 1.4rem;
}

.image{
  max-width: 240px !important;
}

.triangle {
  width: 0;
  height: 0;
  border: 25px solid transparent;
  border-top: 0;
  border-bottom: 39px solid #ffff;
  position: absolute;
  top: -16px;
  right: 79px;
}

.newCircle{  
  height: 12px;
  width: 12px;
  background-color: #53BF9D;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
}

.alertTime {
  color: #9a9a9a;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.clearButton{
  text-decoration: underline;
  font-weight: 500;
}

.checkIcon svg{
  transform: scale(1.2);
}