.missionBubble{
    border: 1px solid #C0CDD5;
    border-radius: 4px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.5rem;
}

.wantButton{
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: #F2F5F6;
    color: #033858;
    font-weight: 500;
}

.prizeIcon{
    height: 24px;
    transform: translateY(-2px);
}