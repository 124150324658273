  .itemCompleteCounter {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
  }

  .disabled {
      background-color: #EBEBE4 !important;
  }

  .labelDisabled {
      color: #BDBDBD !important;
  }

  .hasBackground {
      background-color: #E6F1F4;
      width: 43px;
      border-radius: 0 4px 4px 0;
  }

  .addNew {
      border: none;
      border-radius: 4px;
      background-color: #E6F1F4;
      color: #033858;
      font-weight: 500;
      height: 28px;
  }

  .addToCart {
      display: flex;
      align-items: center;
      padding: 0.1rem 0.9rem 0.1rem 0.9rem;
      gap: 0.2rem;
  }

  .itemCardCounter {
      margin-inline: 0.4rem;
  }

  .addToCart img {
      transform: translateY(-1px);
  }

  .cardCounter {
      height: 100%;
      display: flex;
      justify-content: space-between;
  }

  .add {
      background-color: #E6F1F4;
      border-radius: 0 4px 4px 0;
      border: none;
      font-size: 1.1rem;
      font-weight: 500;
      color: #033858;
      width: 38px;
      height: 28px;
  }

  .barCounter {
      color: #033858;
      text-align: center;
      border: none;
      background-color: #FFFFFF;
      font-weight: 500;
      font-size: 14px !important;
      width: 40%;
  }

  .remove {
      background-color: #E6F1F4;
      border-radius: 4px 0 0 4px;
      border: none;
      font-size: 1.25rem;
      font-weight: 500;
      color: #033858;
      width: 38px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .removeTrash {
      background-color: #E6F1F4;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      margin-right: 0.8rem;
      padding: 0.5rem;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .removeTrash img {
      width: 16px;
  }
