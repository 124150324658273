.topBar {
  padding-top: 1rem;
  padding-bottom: 0.01rem;
  text-align: center;
  color: var(--mainColor);
  font-weight: 600;
  display: flex;
  margin-right: 0px;
  --bs-gutter-x: 0rem;
  background-color: #FFFFFF;
}

/* .sticky{
  position: sticky;
  top: 0;
  z-index: 5;
} */

.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 6;
}

.topBar span {
  position: absolute;
  left: 1rem;
  display: flex;
  margin-block: 3px;
  max-width: 90vw;
}
