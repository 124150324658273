.modalBody{
    box-shadow: 5px 5px 20px #02283f40;
    position: fixed;
    display: block;
    top: 90%;
}

.modalContainer{
    height: 0.5rem;
    background-color: #033858;
    width: 100%;
    border-radius: 8px 8px 0 0;
}

.buttonAccept{
    border: 2px solid #033858;
    border-radius: 8px;
    color: #f0f0f0;
    background-color: #033858;
    font-size: 1.1rem;
    margin-inline: 0.5rem;
    width: 100px;
}

.buttonRefuseMobile{
    border-radius: 8px;
    color: #868686;
    background-color: #E7E7E7;
    border: none;
    font-size: 1.1rem;
    margin-inline: 0.5rem;
    width: 100px;
}

.buttonRefuse{
    border: 2px solid #033858;
    border-radius: 8px;
    color: #033858;
    font-weight: 600;
    font-size: 1.1rem;
    background-color: transparent;
    margin-inline: 0.5rem;
}

.coockieIcon{
    width: 22px;
    margin-right: 1rem;
}

.XIcon{
    width: 10px;
}