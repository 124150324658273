  .productContainer{
    display: flex; 
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    height: 137px;
  }

  .imageStyle{
    height: 80px;
  }

  .productId{
    color: #868686;
    font-size: 0.9rem;
    display: flex;
  }

  .productId span{
    margin-right: 1rem;
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  }

  .price{
    text-decoration: line-through;
    font-size: 0.9rem;
  }

  .discount{
    font-size: 1.1rem;
    font-weight: 500;
  }

  .productImage{
    cursor: pointer;
    width: 64px;
  }

  .cartDesktopCardDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }
  
  .cartDesktopCardDescription img {
    margin-block: 1rem;
    margin-right: 2rem;
  }

  .trashCan {
    display: flex;
    justify-content: center;
    font-size: 1.8rem;
    color: #7994a4;
    transform: translateY(-2px);
  }
  
  .trashCan :hover {
    color: var(--mainColor);
  }

  .deleteButton{
    background-color: #D61C4E;
    border-radius: 8px;
    color: #f0f0f0;
    font-weight: 500;
    padding-block: 0.3rem;
    padding-inline: 1.2rem;
    cursor: pointer;
  }

  .deleteButton:hover{
    background-color: #AB163F;
  }

  .cancelButton{
    background-color: #DBDBDB;
    border-radius: 8px;
    color: #868686;
    font-weight: 500;
    padding-block: 0.3rem;
    padding-inline: 1.2rem;
    cursor: pointer;
  }
  .cancelButton:hover{
    background-color: #c2c2c2;
    color: #868686;
  }