.sticky{
  position: sticky;
  top: 45px;
  z-index: 5;
}

.fixed {
  position: fixed;
  width: 100%;
  top: 45px;
  z-index: 6;
}