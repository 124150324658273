.addProducts{
    border-radius: 4px;
    background-color: #f2f5f6;
    color: #033858;
    padding: 0.5rem;
    text-align: center;
    font-weight: 500;
    margin-top: 1rem;
}

.prizeIcon{
    margin-right: 0.1rem;
}

.bar{
    background: linear-gradient(135deg, rgba(83,191,157,1) 0%, rgba(4,115,140,1) 50%, rgba(3,56,89,1) 100%);
}