.mainData{
    background-color: #F2F5F6;
    border-radius: 8px;
    border: 1px solid #C0CDD5;
    padding: 1rem 0.8rem;
}

.profileOptions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F2F5F6;
    border-radius: 8px;
    border: 1px solid #C0CDD5;
    padding: 1rem 0.5rem;
    margin-top: 1rem;
}

.editIcon{
    transform: translateY(-2px);
    margin-left: 0.5rem;
    width: 16px;
}