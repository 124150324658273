/* LIST */

.listContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #C0CDD5;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1.5rem;
    box-shadow: 4px 4px 6px 0px #54565c3b;
}

.listImage{
    height: 72px;
    width: 72px;
}

.proceedButton{
    background-color: #E6F1F4;
    color: #033858;
    padding: 0.5rem 1rem;
    border-radius: 16px;
    font-weight: 600;
}

.emptyPage {
    border: 1px solid #C0CDD5;
    text-align: center;
    border-radius: 16px;
    padding: 2rem;
    width: 100%
}

/* LIST DETAIL */

.itemGroupName{
    font-weight: 500;
    color: #033858;
    font-size: 1.1rem;
    max-width: 265px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.cartItemClassButton{
    border: none;
    color: var(--mainColor);
    background-color: #E6F1F4;
    padding: 0.2rem 1rem;
    border-radius: 4px;
    font-weight: 600;
}

.linkStyle{
    text-decoration: underline;
    color: #033858;
    font-weight: 500;
}