.AppLogo {
  height: 32px;
  pointer-events: none;
}

.functionBar {
  display: flex;
  justify-content: space-between;
  background-color: #F2F5F6;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0rem;
}

.navBar a {
  color: var(--mainColor);
  text-decoration: none;

  font-weight: 500;
}

.navBarLinks {
  text-decoration: 'none';
  position: relative;
  color: #033858;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0.8rem;
  border-right: 1px solid #C0CDD5;
}

.navBarIcons{
  height: 22px;
}

.navBarLinks :hover {
  cursor: pointer;
}

button.CartCounter {
  display: flex;
  position: absolute;
  background-color: var(--secondColor);
  color: white;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  font-feature-settings: 'pnum';
  font-variant: proportional-nums;
  transform: translate(30px, -10px);
  justify-content: center;
}

button.CartCounter:hover {
  background-color: var(--mainColor);
  cursor: pointer;
}

button.NotificationCounter{
  display: flex;
  position: absolute;
  background-color: var(--secondColor);
  color: white;
  border: none;
  margin-top: 1px;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  font-feature-settings: 'pnum';
  font-variant: proportional-nums;
  transform: translate(10px, -10px);
  align-items: center;
  justify-content: center;
}

button.NotificationCounter:hover {
  background-color: var(--mainColor);
  cursor: pointer;
}

.cartArea {
  display: contents;
}

.cartIcon {
  /* transform: translateY(5px); */
  width: 45px;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--mainColor);
  display: none;
  opacity: 0;
  font-size: 1.6rem;
}

.navBar {
  display: flex;
  align-items: center;
}

.priceBubble {
  background-color: var(--mainColor);
  padding-block: 0.1rem;
  padding-inline: 0.7rem;
  border-radius: 16px;
  color: white;
  display: flex;
  align-items: center;
}

.menuPrimaryEnter {
  position: absolute;
  transform: translateX(-110%);
}

.menuPrimary:hover {
  color: var(--mainColor) !important;
}

.menuPrimaryEnterActive {
  position: absolute;
  transform: translateX(0%);
  transition: all 300ms ease;
}

.menuPrimaryExit {
  position: absolute;
}

.menuPrimaryExitActive {
  transform: translateX(-110%);
  transition: all 300ms ease;
}

.burgerMenu {
  font-size: 1.7rem;
  color: #033858;
  cursor: pointer;
}

.burgerMenuContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 90px;
  left: 10px;
  background-color: #ffff;
  border: 2px solid var(--secondColor);
  border-radius: 16px;
  padding: 1rem;
  z-index: 3;
}

.Searchbar {
  width: 360px;
}

/* LIST OF CATEGORIES */

.categoriesMenuExpanded {
  z-index: 6;
  padding-top: 1rem;
  max-height: 50vh;
  overflow-y: auto;
  width: 100%;
}

.categoriesMenuReduced {
  z-index: 6;
  padding-block: 1rem;
  max-height: 50vh;
  overflow-y: auto;
}

.listContent {
  color: #819cac;
}

.listContent:last-child {
  margin-bottom: 0;
}

.listContent:last-child {
  margin-bottom: 0;
}

.listContentTopic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.listContent .listContentTopic {
  border-bottom: 1px solid transparent;
}

.listContent .listContentTopic:hover {
  color: #033858;
  border-bottom: 1px solid #0337585e;
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
    brightness(100%) contrast(98%);
}

.classIcon {
  height: 20px;
  width: 25px;
  margin-right: 0.2rem;
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
    brightness(100%) contrast(98%);
}

.divider {
  border-bottom: 1px solid #0337585e;
  width: 100%;
  margin-block: 0.8rem;
}

.postLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #819cac;
  margin-top: 1rem;
  cursor: pointer;
}

.phoneLink {
  color: #819cac !important;
}

@media screen and (min-width: 576px) {
  .container, .container-sm {
      max-width: 800px !important;
  }
}