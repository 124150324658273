.verticalGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.6rem 1rem;
}

@media screen and (max-width:380px) {
    .verticalGrid {
        gap: 1.2rem 0.6rem;
    }
}