.breadcrumbs {
    max-width: 1200px;
}

.breadcrumbs > *{
    display: inline-block;
    margin-inline: 0.5rem;
}

.breadcrumbs > *:first-child{
    margin: 0 0.5rem 0 0;
}

.crumb a{
    color: #033858 !important;
    text-decoration: none;
}

.crumb{
    color: #033858 !important;
    text-decoration: none;
}

.crumb a:hover{
    font-weight: 500;
}

.crumb:hover{
    font-weight: 500;
}

/* .breadcrumbs .crumb:after {
    content: '>';
    margin-left: 0.5rem;
    color: #033858;
    font-weight: 500;
}

.breadcrumbs .crumb:last-child:after {
    display: none;
} */

