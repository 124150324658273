.contactBigButton {
  border: 1px solid var(--borderColor);
  border-radius: 8px;
}

.contactBigButtonBottom {
  background-color: #F2F5F6;
  color: var(--mainColor);
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.copyIcon{
  transform: scale(1.2) translateY(-1px);
  filter: invert(70%) sepia(0%) saturate(131%) hue-rotate(150deg) brightness(77%) contrast(78%);
  margin-left: 0.5rem;
}

.contactBigButtonTittle {
  font-weight: 600;
  font-size: 1.2rem;
}
