.saleContainer{
    margin-bottom: 4rem;
}

.saleContainer div a{
 width: auto;
}

.saleContainer div:first-child{
    margin-bottom: 0rem;
}

.productContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;
}

@media (max-width: 768px) {
    .productContainer{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px;
    }
}