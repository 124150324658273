.ordersFormSelector label {
    color: var(--mainColor);
}
  
.ordersFormSelector {
    color: #033858;
    border-radius: 8px;
    border: none;
    padding-block: 0.2rem;
    padding-inline: 1rem;
    outline: none;
}
  
.ordersFormSelector img{
    background-color: #F2F5F6;
    padding: 0.5rem;
    border-radius: 8px;
}

.ordersFormSelectorOpen {
    color: #033858;
    border-radius: 8px;
    border: none;
    padding-block: 0.2rem;
    padding-inline: 1rem;
    outline: none;
}

.ordersFormSelectorOpen img{
    background-color: #D9E1E6;
    padding: 0.5rem;
    border-radius: 8px 8px 0 0;
}

.buttonShadow{
    box-shadow: 4px 4px 8px #0000001a;
    border-radius: 8px;
    z-index: 10;
}

.container{
    position: relative;
}

.buttonClosed{
    background-color: #F2F5F6;
    font-weight: 500;
    border: 1px solid transparent;
    color: #033858;
    width: fit-content;
    padding-inline: 3.5rem;
    padding-block: 0.2rem;
    border-radius: 8px;
    min-width: 8rem;
    text-align: center;
}

.buttonOpen{
    background-color: #ffff;
    font-weight: 500;
    border: 1px solid #C0CDD5;
    color: #033858;
    width: fit-content;
    border-radius: 8px 0 8px 8px;
    min-width: 8rem;
    text-align: center;
    margin-right: 1rem;
    transform: translateY(-3px);
    box-shadow: 0px 6px 5px #54565c3b;
}

.buttonOpen:focus{
    border: none;
}

.buttonOpen option{
    padding-block: 0.2rem;
    padding-inline: 3.5rem;
    cursor: pointer;
}

.buttonOpen option:first-child{
    border-radius: 8px 0 0 0;
}

.buttonOpen option:last-child{
    border-radius: 0 0 8px 8px;
}

.buttonOpen option:hover{
    background-color: #D9E1E6;
}

.buttonContainer{
    position: absolute;
    top: 32px;
}

.chevronDown{
    transition: transform 0.3s ease;
    transform: rotate(180deg);
  }
  
  .chevronUp{
    transition: transform 0.3s ease;
  }

  .chevronUp path{
    transform: translateY(-1px);
  }

.filterOptions{
    z-index: 6;
    position: absolute;
    border-radius: 0 0 8px 8px;
    width: 100%;
    border: 1px solid #C0CDD5;
    box-shadow: 4px 4px 8px #0000001a;
}

.singleOption{
    padding-block: 0.5rem;
    background-color: #F2F5F6;
    border-bottom: 1px solid #C0CDD5;
    text-align: center;
    cursor: pointer;
}

.singleOption:last-child{
    border-radius: 0 0 8px 8px;
}

.singleOption:hover{
    font-weight: 500;
}

.filterOptions :last-child{
    border-bottom: none;
}