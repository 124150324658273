.wholeMobileCard {
    margin-bottom: 2.5vh;
    display: flex;
    align-items: center;
    padding: 0px;
    flex-grow: 1;
    width: 100%;
}

.cardMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 120px;
    position: relative;
    overflow: hidden;
    padding: 0.5rem;
}

.btn {
    --bs-btn-border-width: 0px !important;
}

.btns {
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid #C0CDD5;
}

.btnUnitM {
    border-bottom-left-radius: 4px;
    padding-block: 0.2rem;
    color: #033858;

    >button>span {
        font-weight: 500;
    }
}

.btnBoxM {
    border-bottom-right-radius: 4px;
    padding-block: 0.2rem;

    >button>span {
        font-weight: 500;
    }
}

.btnBoxMActive {
    background-color: #E6F1F4;

    >button>span {
        font-weight: 600;
        color: #033858;
    }
}

.btnUnitMActive {
    background-color: #E6F1F4;

    >button>span {
        font-weight: 600;
    }
}

.badgeStyle{
    position: absolute;
    display: flex;
    left: 4px;
    top: -0.8rem;
}

.cardImageMobile {
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 0.2rem;
}

.productCardImage{
    height: 80px;
}

.cardBodyMobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding-inline: 0.5rem;
    transform: translateY(6px);
}

.itemCardMobile {
    border: 1px solid #C0CDD5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
}

.itemCardAddition {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 124px;
    border-radius: 0 4px 4px 0;
    width: 48px;
    border: 1px solid #C0CDD5;
    border-left: none;
    /* background-color: #E6F1F4; */
}

.add {
    font-size: x-large;
    color: var(--mainColor);
    border: none;
    background-color: #fff0;
    font-weight: 600;
}

.remove {
    font-size: x-large;
    color: var(--mainColor);
    border: none;
    background-color: #fff0;
    font-weight: 600;
}

.itemMobileCardCounter {
    margin-top: 15px;
    margin-bottom: 10px;
    color: #033858;
    font-weight: 600;
    font-size: 1.3rem;
}

.productName {
    display: -webkit-box;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    margin-block: 0.3rem;
    margin-top: 1rem;
    padding-right: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 36px;
    max-height: 36px;
}

.clipboardIcon {
    z-index: 4;
}

.prices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    color: var(--mainColor);
}

.mainPrice {
    font-weight: 600;
    color: var(--mainColor);
    font-size: 1.2rem;
}

.secundaryPrices {
    font-weight: 500;
    font-size: 1rem;
}

.btnBoxContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0px;
    color: #033858;
    width: 100%;
    padding-inline: 1.5rem;
}

.unitComparative {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
}

.unitType{
    text-transform: uppercase;
}

.cesta {
    width: 18px;
}

.caixa {
    width: 20px;
}

@media screen and (max-width:345px){
    .btnBoxContent{
        padding-inline: 0.5rem;
    }
    .unitType{
        transform: translateY(1px);
    }
}
