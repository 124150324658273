.balanceDisplay {
  color: var(--mainColor);
  background-color: #e6e9ea;
  border-radius: 16px;
}

.paymentsFormSelector label {
  color: var(--mainColor);
}

.paymentsFormSelector select {
  background-color: #e6e9ea;
  border-radius: 8px;
  border: none;
  padding-block: 0.2rem;
  padding-inline: 1rem;
}

.paymentsFormSelector select:focus-visible {
  outline: none;
}

.paymentsForm {
  background-color: none !important;
  border-radius: 0px !important;
  padding: 0rem !important;
}

.payment {
  text-decoration: none;
  color: black;
}

.paymentBubbleStatus {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: inline-block;
  max-width: 100px;
  border-radius: 8px;
  padding-block: 0.2rem;
  padding-inline: 0.5rem;
  color: #f0f0f0;
  font-weight: 500;
}

.paymentBubble {
  border: 1px solid #c4ced4;
  border-radius: 8px;
  font-weight: 500;
}

.leserDetails {
  font-weight: 500;
  font-size: 0.9rem;
}

.idStyle{
  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.bubbleStatusAprovado {
  background-color: #5ccb58;
  display: flex;
  justify-content: center;
}

.bubbleStatusCancelado {
  background-color: #f15555;
  display: flex;
  justify-content: center;
}

.bubbleStatusAguardando {
  background-color: #3f8acb;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  text-align: center;
}
