.addNew {
    border: none;
    border-radius: 8px;
    background-color: #C0DCE2;
    padding-block: 0.3rem;
  }

  .addNew:hover{
    box-shadow: 8px 8px 14px #0d0d0d1a;
  }

  .addToCart {
    font-weight: 500;
    display: flex;
    padding-block: 0.3rem;
    padding-inline: 1rem;
    color: #033859;
    border: none;
    column-gap: 0.25rem;
  }

  .addToCart p{
    display: flex;
    align-items: flex-end;
    margin: 0;
    line-height: 1;
  }

  .itemCardCounter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: 1px solid #D9E1E6;
    background-color: #fff;
    border-radius: 8px;
    z-index: 2;
  }

  .remove{
    border: none;
    font-weight: 500;
    width: 32px;
    background-color: #C0DCE2;
    border-radius: 8px 0 0 8px;
    height: 32px;
  }

  .remove:focus{
    background-color: #82B9C6;
  }

  .barCounter{
    width: 5rem;
    background-color: transparent;
    text-align: center;
    border: none;
    font-weight: 500;
    font-size: 1.1rem !important;
    color: #033858;
  }

  .add{
    border: none;
    font-weight: 500;
    font-size: 0.9rem;
    width: 32px;
    height: 32px;
    background-color: #C0DCE2;
    border-radius: 0 8px 8px 0;
  }

  .add:focus{
    background-color: #82B9C6;
  }
