.btnPrimary {
    border: none;
    padding: 0px;
    border-radius: 8px;
    background-color: #ffffff;
}

.btnPrimary:hover {
    box-shadow: 8px 8px 14px #0d0d0d1a;
}

.price {
    transform: translateY(3px);
    font-size: 1.1rem;
    margin: 0px;
}

.priceSmall {
    font-size: 1rem;
    margin: 0px;
}

.priceSmall div div{
    transform: translateY(4px);
}

.btnSelected {
    display: flex;
    border-bottom: 2px solid #C0DCE2;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 8px;
    padding: 0px;
}

.btnSelected .price {
    font-weight: 600;
}

.btnSelected span {
    color: #033859 !important;
}

.btnItemFaded {
    display: flex;
    border-bottom: 2px solid #C0DCE2;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 8px;
    padding: 0px;
    text-align: center;
}

.btnSelected button {
    background-color: #c0dce2;
}

.values {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    transform: translateY(-3px);
}

.discount {
    font-weight: 500;
    text-decoration: line-through;
}
