.dropdown{
    position: relative;
}

.button{
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #868686;
    border-radius: 8px;
    background-color: #ffff;
    font-weight: 500;
  }

  .visible{
    opacity: 1 !important;
    display: block !important;
    transform: translateY(0) !important;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .dropdownContent{
    opacity: 0;
    display: none;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 2;
    position: absolute;
    width: 100%;
  }

  .content{
    width: 100%;
    border: 1px solid #868686;
    border-radius: 8px;
    background-color: #ffff;
    font-weight: 500;
  }

  .selectedOptionContent{
    color: #f0f0f0;
    background-color: #033858;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #868686;
  }

  .selectedOptionContent:first-of-type{
    border-radius: 8px 8px 0 0;
  }

  .selectedOptionContent:last-of-type{
    border-radius: 0 0 8px 8px;
    border-bottom: none;
  }

  .optionContent{
    padding-block: 0.3rem;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #868686;
  }

  .optionContent:last-of-type{
    border-bottom: none;
  }