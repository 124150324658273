.orderBubble {
    border: 1px solid #c4ced4;
    border-radius: 8px;
    font-weight: 500;
}

.orderBubbleStatus {
    border-radius: 8px;
    padding-block: 0.1rem;
    padding-inline: 0.5rem;
    color: #f0f0f0;
    font-weight: 500;
}

.orderBubbleSpans span {
    margin-bottom: 0.6rem;
}

.bubbleStatus1 {
    background-color: #3f8acb;
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    text-align: center;
}

.bubbleStatus2 {
    background-color: #e8ae6d;
}

.bubbleStatus3 {
    background-color: #f15555;
}

.bubbleStatus4 {
    background-color: #5ccb58;
}

.orderIcons {
    margin-inline: 1rem;
}

.orderIconsButtons {
    color: #033858;
    font-weight: 400;
    border-radius: 8px;
    padding-inline: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 0px;
    width: 0;
    white-space: nowrap;
    padding-block: 10px;
    cursor: pointer;
    font-size: 0.8rem;
    margin: 0.25rem;
}
