.authenticationConfirmationSheet{
    scroll-behavior: unset;
    overflow: clip;
}

.modalBodyDisabling {
    background-color: #f0f0f0;
    border-radius: 16px;
    padding: 1rem;
}

.noButton {
border: none;
border-radius: 8px;
color: #868686;
background-color: #dbdbdb;
font-weight: 500;
padding-block: 0.3rem;
padding-inline: 1.2rem;
}