.itemCardCounter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.disabled {
    background-color: #EBEBE4 !important;
}

.labelDisabled {
    color: #BDBDBD !important;
}

.activeCounter{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hasBackground{
  background-color: #E6F1F4;
  width: 43px;
  border-radius: 0 4px 4px 0;
}

.cardCounter{
  width: 43px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add{
  background-color: #E6F1F4;
  border-radius: 0 4px 0 0;
  border: none;
  font-size: 1.1rem;
  font-weight: 500;
  color: #033858;
  /* width: 36px; */
  height: 48px;
}

.barCounter{
  color: #033858;
  text-align: center;
  border: none;
  background-color: #FFFFFF;
  font-weight: 500;
  /* width: 36px; */
  padding-block: 1rem;
}

.remove{
  background-color: #E6F1F4;
  border-radius: 0 0 4px 0;
  border: none;
  font-size: 1.25rem;
  font-weight: 500;
  color: #033858;
  /* width: 36px; */
  height: 48px;
}

.itemCardCounter:has(.addToCart){
  background-color: #E6F1F4;
  /* border-radius: 0 4px 4px 0; */
  flex: 1 1 auto !important;
  align-items: center !important;
}

.addToCart{

}

.addToCart img{
  width: 32px;
}

.removeTrash{
  background-color: #E6F1F4;
  border-radius: 0 0 4px 0;
  height: 48px;
  /* width: 36px; */
  display: flex;
  justify-content: center;
}

.removeTrash img{
  width: 18px;
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
  brightness(100%) contrast(98%);
}
