.customAnimation {
  animation: slideInRight 0.5s ease-in-out;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.carrinhoTitle {
  display: flex;
  justify-content: center;
  color: var(--mainColor);
  font-weight: 600;
  font-size: 1.2rem;
  margin-block: 0rem;
}

.cartIcon{
  width: 33px;
  margin-right: 1rem;
}

.textPartialRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--mainColor);
}

.font-11-weight-500 {
  font-weight: 500;
  font-size: 1.1rem;
}

.cartBtn {
  border: none;
  border-radius: 8px;
  color: white;
  background-color: var(--mainColor);
  padding-block: 0.5rem;
  width: auto;
  padding-inline: 2rem;
  display: flex;
  align-items: center;
  z-index: 5;
}

.cartBtn:hover {
  background-color: var(--secondColor);
  color: white;
  cursor: pointer;
}

.missionContainer{
  margin-inline: 0.5rem;
  border: 1px solid #C0CDD5;
  border-radius: 8px;
  padding: 1rem;
}

.iconStyle {
  height: 100px;
  width: 80px;
  border-radius: 8px;
  padding: 0.2rem;
}

.dropdownCart {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 580px;
  height: 100vh;
  background-color: #ffff;
  padding: 1rem;
  z-index: 9;
}

.CartCounter {
  display: flex;
  position: relative;
  background-color: var(--secondColor);
  color: white;
  border: none;
  width: 24px;
  height: 25px;
  border-radius: 20px;
  font-feature-settings: 'pnum';
  font-variant: proportional-nums;
  top: 20px;
  right: -65px;
  justify-content: center;
}

.CartCounterLong {
  display: flex;
  position: relative;
  background-color: var(--secondColor);
  color: white;
  border: none;
  width: 38px;
  height: 25px;
  border-radius: 20px;
  font-feature-settings: 'pnum';
  font-variant: proportional-nums;
  top: 20px;
  right: -65px;
  justify-content: center;
}

button.CartCounter:hover {
  background-color: var(--mainColor);
  cursor: pointer;
}

.linkForMore {
  text-decoration: underline;
  margin-top: 1rem;
  color: var(--mainColor);
  cursor: pointer;
}

.modal > *:first-child{
  position: fixed;
  width: 500px;
  right: 0;
  bottom: 0px;
  top: 0px;
  margin: 0px;
}

.modal > *:first-child > *:first-child{
  border-radius: 0px;
  height: 100%;
}

.carrinhoBody{
  padding: 0px;
  overflow: auto;
  position: static;
}

.container{
  margin-bottom: 95px;
}

.product{
  display: flex;
  border-bottom: 1px solid #C0CDD5;
  padding: 1.5rem 1rem;
  max-height: 155px;
}

.productContent{
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-start;
  padding-top: 0.2rem;
}

.productName{
  font-weight: 500;
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 305px;
}

.barcode{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  max-width: 190px;
  font-size: 0.8rem;
  color: #868686;
}

.boxBadge{
  color: #868686;
  font-weight: 400;
  font-size: 0.9rem;
  margin-left: 0.6rem;
}

.footer{
  background-color: #E6F1F4;
  padding: 1.5rem;
  position: absolute;
  bottom: 0px;
  z-index: 5;
}

.trashCan {
  color: #7994a4;
}

.trashCan :hover {
  color: var(--mainColor);
  cursor: pointer;
}
