.priceBubble {
  background-color: var(--mainColor);
  padding-block: 0.1rem;
  padding-inline: 0.7rem;
  border-radius: 16px;
  color: white;
  display: flex;
  align-items: center;
}

.cartIcon {
  /* transform: translateY(5px); */
  width: 45px;
}

button.CartCounter {
  display: flex;
  position: absolute;
  background-color: var(--secondColor);
  color: white;
  border: none;
  width: fit-content;
  min-width: 25px;
  height: 25px;
  border-radius: 20px;
  font-feature-settings: 'pnum';
  font-variant: proportional-nums;
  transform: translate(30px, -10px);
  justify-content: center;
}

button.CartCounterLong {
  display: flex;
  position: absolute;
  background-color: var(--secondColor);
  color: white;
  border: none;
  width: 38px;
  height: 25px;
  border-radius: 20px;
  font-feature-settings: 'pnum';
  font-variant: proportional-nums;
  transform: translate(30px, -10px);
  justify-content: center;
}

button.CartCounter:hover {
  background-color: var(--mainColor);
  cursor: pointer;
}

button.CartCounterLong:hover {
  background-color: var(--mainColor);
  cursor: pointer;
}

.cartArea {
  display: contents;
}

.navBarLinks {
  text-decoration: 'none';
  position: relative;
  color: #033858;
  display: inline-flex;
  align-items: center;
}
