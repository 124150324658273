.installmentsTable{
  border: 1px solid var(--borderColor);
  border-radius: 8px;
  padding: 0.5rem;
}

.totalBackground {
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
  margin-block: 1.5rem; 
  padding: 0.5rem; 
  font-weight: 600; 
  color: #033858;
  background-color: #D9E1E6;
  border-radius: 8px;
}

.bubbleStatus {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: inline-block;
  border-radius: 8px;
  padding-block: 0.2rem;
  padding-inline: 0.5rem;
  color: #f0f0f0;
  font-weight: 500;
  margin-bottom: 4px;
}

.bubbleStatusAprovado {
  background-color: #5ccb58;
  display: flex;
  justify-content: center;
}

.bubbleStatusCancelado {
  background-color: #f15555;
  display: flex;
  justify-content: center;
}

.bubbleStatusAguardando {
  background-color: #3f8acb;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  text-align: center;
}
