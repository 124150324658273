.headers{
    text-align: center;
}

.headers th{
    padding-block: 0.5rem;
    border-bottom: 1px solid #4A4A4A;
}

.bodyContent{
    text-align: center;
}

.cellContent:first-child{
    /* white-space: nowrap; */
    /* overflow: hidden;
    text-overflow: ellipsis; */
    text-align: left;
    padding-left: 1.5rem;
    padding-block: 0.3rem;
}