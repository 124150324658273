.itemCardMobile {
    border: 1px solid #C0CDD5;
    border-radius: 4px;
}

.clipboardIcon {
    position: relative;
}

.cardMobile {
    /* display: flex;
    flex-direction: column; */
}

.itemCardAddition {
    display: flex;
    justify-content: center;
    margin-top: 0.3rem;
}

.cardProductTitle {
    display: flex;
    align-items: center;
    height: 60px;
}

.cardProductName {
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.5rem;
    max-height: 54px;
}

.productCardImage {
    height: 120px;
    margin-top: 0.5rem;
}

.prices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    color: var(--mainColor);
    height: 34px;
    padding: 0 0.5rem 0.5rem 0.5rem;
}

.mainPrice {
    font-weight: 600;
    color: var(--mainColor);
    font-size: 1.2rem;
}

.unitComparative {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
}

.cardMobileDiscountTag {
    color: #f0f0f0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0.15rem 0.5rem;
    width: fit-content;
}

.btns{
    border-top: 1px solid #C0CDD5;
}

.freeShipping {
    background-color: #862FA0;
}

.highDiscountTag {
    background-color: #F94C66;
}

.lowDiscountTag {
    background-color: #2f38a0;
}

.btnUnitM {
    border-radius: 0 0 0 4px;

    >button>span {
        font-weight: 500;
        color: #033858;
        transform: translateY(1px);
    }
}

.unavailable{
    font-size: 0.75rem;
}

.btnUnitMActive {
    background-color: #E6F1F4;

    >button>span {
        font-weight: 600;
    }
}

.btnBoxM {
    border-radius: 0 0 4px 0;

    >button>span {
        font-weight: 500;
        color: #033858;
    }
}

.btnBoxMActive {
    background-color: #E6F1F4;

    >button>span {
        font-weight: 600;
    }
}

.whishListIcon div img{
    right: 5px !important;
    top: 5px !important;
}