.container{
    padding: 1.5rem;
    border: 1px solid #819CAC;
    border-radius: 8px;
    position: relative;
    height: 380px;
}

.container:hover{
    box-shadow: 1px 5px 10px #00000025;
}

.header{
    background-color: #E6F1F4;
    border-radius: 8px;
    color: #033858;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.5rem 0.8rem 1rem;
}

.missionContent{
    display: flex; 
    flex-direction: column; 
    margin-top: 1.5rem;
}

.missionDescription{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button{
    background-color: #E6F1F4;
    border-radius: 4px;
    font-weight: 600;
    margin-top: 1.5rem;
    padding: 1rem;
    color: #033858;
    text-align: center;
    font-size: 1.2rem;
    cursor: pointer;
    position: absolute;
    bottom: 1rem;
    width: calc(100% - 48px);
}
