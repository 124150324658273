.formControl{
    border: 1px solid #0D0D0D;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    width: 100%;
    background-color: #fff;
}

.formControl:required:invalid{
    color: gray;
}

.formControl option{
    color: #0D0D0D
}

.formControl option:first-of-type{
    display: none;
}

.formControl option span{
    color: #4A4A4A;
    /* #b7b7b7 */
}

.cardNumberForm{
    position: relative;
    z-index: 1;
}

.cardIcon{
    position: absolute;
    right: 20px;
    top: 7px;
    z-index: 2;
}

.addButton{
    position: fixed;
    bottom: 58px;
    display: flex;
    justify-content: center;
    padding-block: 1rem 1.1rem;
    border-radius: 16px 16px 0 0;
    background-color: #53BF9D;
    color: #f0f0f0;
    width: 100%;
}