.arrowBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccd4d9;
  color: var(--mainColor);
  padding-block: 0.5rem;
  overflow: hidden;
  border: 1px solid #c0cdd5;
  border-radius: 8px;
}

.arrowBoxInactive {
  color: #7491a1;
  padding-block: 0.5rem;
  background-color: #e6e9ea;
  border: none;
  border-radius: 8px;
  justify-content: center;
  cursor: pointer;
}

.activeStep {
  background-color: #ccd4d9;
  color: var(--mainColor);
  cursor: pointer;
}

.inactiveStep {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.step {
  text-decoration: none;
  font-weight: bold;
  height: auto;
  font-size: 1rem;
}

.stepCol {
  width: 24%;
  flex: 0 0 auto;
}
