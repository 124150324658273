.modalContainer{
    scroll-behavior: unset;
    overflow: clip;
}

.modalBodyDisabling {
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 1rem;
}

.formStyle label{
    margin-bottom: 1rem;
}

.formStyle label input{
    border: 1px solid #aba5a5;
    border-radius: 4px;
    padding-left: 1rem;
}

.formStyle label span{
    font-weight: 500;
}

.dates{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0rem 0.5rem;
}

.dateInput{
    max-width: 170px;
}

@media screen and (min-width: 576px) {
    .modalContainer > *:first-child{
      --bs-modal-width: 400px !important;
    }
  }