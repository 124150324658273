.search input {
  background-color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  padding: 1.5rem;
  height: 1.5rem;
  width: 40vw;
  padding-left: 2rem;
  z-index: 3;
  border: 1px solid var(--mainColor);
}

.searchInput {
  border: none;
  box-shadow: 0px 2px 1px #819CAC;
  width: 100%;
  border-radius: 8px;
  padding-left: 2.2rem;
  padding-block: 0.3rem;
  width: 32vw;
  position: inherit;
  z-index: 3;
  margin-inline: 1rem;
  font-size: 20px;
  border: 1px solid transparent;
}

.searchInput:focus{
  box-sizing: border-box;
  box-shadow: none;
  border: 1px solid #819CAC;
}

.searchBar {
  border-radius: 8px;
  padding-block: 0.4vw;
  padding-left: 32px;
  background-color: #ffffff;
  border: 1px solid #033858;
  z-index: 3;
}

.searchIcon {
  position: absolute;
  z-index: 4;
  transform: translateX(24px) translateY(10px) scale(0.8);
}

.carousel-caption p {
  margin-bottom: 0.5rem;
  padding-bottom: 0rem;
}

.emptyWrapper {
  display: grid;
  position: relative;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 200ms;
}

.emptyWrapperExpanded {
  grid-template-rows: 1fr;
  transition: grid-template-rows 200ms;
}

.searchList {
  box-shadow: 0px 5px 15px rgb(0 0 0 / 35%);
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  z-index: 2;
  border-radius: 8px;
  padding-top: 4rem;
  margin-top: -3rem;
}

.searchList::-webkit-scrollbar {
  display: none;
}

.search-inputs-open {
  display: flex;
  flex-direction: row-reverse;
}

.smallSearch{
  transform: scale(0.7);
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
    brightness(100%) contrast(98%) !important;
}

.sugestedName{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 200px; */
}

.suggestionsDivision{
  background-color: #033858;
  font-size: 1rem;
  color: #ffff;
  font-weight: 500;
  padding-top: 3px;
  padding: 0.5rem 1rem;
}

.numberedSearch{
  background-color: #E6E9EA;
  padding: 0.2rem 0.7rem;
  font-size: 1.2rem;
  color: #033858;
  margin-right: 8px;
  border-radius: 8px;
}
