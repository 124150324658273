.ellipsis {
    display: inline-block;
    width: 1em;
    overflow: hidden;
    transform: translateY(8px);
  }
  
  /* Animation */
  @keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .ellipsis span {
    animation: blink 1s infinite;
  }