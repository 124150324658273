.search{
  position: sticky;
  top: 35px;
  background-color: #ffff;
  z-index: 10;
}

.formField {
  position: relative;
  display: inline-block;
  padding-block: 1rem;
  background-color: #ffff;
}

.search input {
  background-color: #F2F5F6;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem !important;
  height: 3rem;
  width: 100%;
  padding-inline: 3rem;
  z-index: 2;
}

.searchInputs {
  display: flex;
  flex-direction: row-reverse;
}

.carouselCaption p {
  margin-bottom: 0.5rem;
  padding-bottom: 0rem;
}

.searchBar{
  font-size: 22px;
}

.searchIcon {
  position: absolute;
  left: 30px;
  color: #033858;
  z-index: 3;
}

.searchIconFaded {
  position: absolute;
  left: 30px;
  filter: invert(80%) sepia(22%) saturate(65%) hue-rotate(75deg) brightness(90%) contrast(87%);
  font-size: 1.8rem;
  z-index: 3;
}

.XIcon {
  position: absolute;
  right: 25px;
  z-index: 3;
  font-size: 1.8rem;
}


.dataItem p {
  width: 100%;
  display: flex;
  align-items: center;
  color: black;
  margin-bottom: 0rem;
}

.searchInputsOpen {
  display: flex;
  flex-direction: row-reverse;
}

#clearBtn {
  cursor: pointer;
}

.completeCardCategory {
  text-align: center;
}

.completeCardCategory:hover .completeCategoryImage {
  background-color: #c0dce2;
}

.completeCardCategory:hover {
  font-weight: 500;
}

.completeCategoryImage {
  background-color: #F2F5F6;
  border-radius: 8px;
  padding-block: 1rem;
  display: flex;
  align-items: center;
}

.suggestion{
  font-weight: 500;
  display: flex;
  align-items: stretch;
  margin-right: 0.5rem;
}
