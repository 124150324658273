.couponAlert {
  background-color: #ccd4d9;
  border-radius: 8px;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  font-size: 0.8rem;
  color: #033858;
}

.orderBubble {
  border: 1px solid #c4ced4;
  border-radius: 8px;
  font-weight: 500;
}

.orderBubbleCupon {
  border-radius: 8px;
  padding-block: 0.2rem;
  padding-inline: 0.2rem;
  color: #f0f0f0;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.bubbleStatusVencido {
  background-color: #e8ae6d;
  max-width: 80px;
}

.bubbleStatusDisponivel {
  background-color: #5ccb58;
  max-width: 100px;
}

.bubbleStatusUsado {
  background-color: #04738c !important;
  max-width: 80px;
}
