.accordionMenu {
  color: var(--mainColor);
}

.profileOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--mainColor);
  border-bottom: 1px solid #819cac59;
  margin-bottom: 1rem;
}

.profileCategory {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 1.2rem;
  cursor: pointer;
}

.profileSubCategories {
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.profileSubCategories span {
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.exitImage{
  filter: invert(70%) sepia(0%) saturate(131%) hue-rotate(150deg) brightness(77%) contrast(78%);
  transform: translateY(-1px);
  
}

.exit{
  color: #868686;
  font-weight: 500;
  margin-left: 3px;
}

.exit:hover{
  font-weight: 600;
}
