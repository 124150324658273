.mobile {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 16px;
    width: 16px;
}

.desktop {
    position: absolute;
    left: 22rem;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 16px;
    width: 16px;
}
