.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.6rem 0.6rem;
    margin-bottom: 5rem;
  }
  
  .completeGrid {
    margin-bottom: 5rem;
  }

  .searchbar{
    position: relative;
    position: sticky;
    top: 44px;
    background-color: #ffff;
  }

  .searchInput {
    border: none;
    background-color: #F2F5F6;
    border-bottom: 1px solid transparent;
    border-radius: 4px;
    width: 100%;
    padding-left: 2rem;
    padding-block: 0.5rem;
  }
  
  .searchInput:focus {
    border-bottom: 1px solid #033859; 
  }

  .searchIcon{
    position: absolute;
    z-index: 5;
    top: 5px;
    left: 16px;
  }

  .searchIcon svg{
    transform: translateX(5px) translateY(1px) scale(1.6);
    color: #033858;
  }
  
  .searchIcon img{
    width: 18px;
  }
  
  .searchInput:focus~.searchIcon {
    filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg) brightness(100%) contrast(98%);
  }
  
  .completeCardBrand {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    background-color: #F2F5F6;
    border-radius: 8px;
    padding-block: 8px;
  }

  .completebBrandImage{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .completeCardBrand:hover .completeBrandImage {
    background-color: #c0dce2;
  }
  
  .completeCardBrand:hover {
    font-weight: 500;
  }
  
  .completeBrandImage {
    background-color: #F2F5F6;
    border-radius: 8px;
    padding-block: 1rem;
    display: flex;
    align-items: center;
  }

  .brandName{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-break: 2;
    line-break: 2;
    -webkit-box-orient: vertical;
  }
  

  /* @media screen and (max-width: 412px){
    .searchIcon img{
      transform: translateX(4px) translateY(-28px);
    }
  } */