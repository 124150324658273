.categoriesContainer {
  background-color: #F2F5F6;
}

.mainMenu {
  color: #033858;
  background-color: #F2F5F6;
  padding: 0.5rem 1rem;
  white-space: nowrap; 
  cursor: pointer;
}

.mainMenuOpen{
  color: #e6e9ea;
  background-color: #033858;
  border-radius: 8px 8px 0 0;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  cursor: pointer;
}

.menuContent {
  display: block;
  flex-direction: row;
  position: absolute;
  z-index: -1;
  /* transform: translateY(40px); */
  opacity: 0;
  top: -100%;
  min-width: 145%;
  transition: opacity 0.3s ease, top 0.5s ease;
}

.menuContent.show{
  opacity: 1;
  top: 100%;
  transition: opacity 0.3s ease, top 0.5s ease;
}

.menuContent.hide{
  opacity: 0;
  top: -100%;
}

.categoriesMenuReduced {
  box-shadow: 0px 6px 5px #54565c3b;
  border-radius: 0px 0px 8px 8px;
  z-index: 6;
  padding-inline: 0.4rem;
  padding-block: 1rem;
  max-height: 50vh;
  overflow-y: auto;
}

.categoriesMenuExpanded {
  box-shadow: 0px 6px 5px #54565c3b;
  border-radius: 0px 0px 0px 8px;
  background-color: #F2F5F6;
  z-index: 6;
  padding-block: 1rem;
  max-height: 50vh;
  overflow-y: auto;
}

.emptyWrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 200ms;
}

.emptyWrapperExpanded {
  grid-template-rows: 1fr;
  transition: grid-template-rows 200ms;
}

.categoriesMenuEmpty {
  box-shadow: 10px 6px 5px #54565c3b;
  border-radius: 0px 0px 8px 8px;
  background-color: #e6e9ea;
  min-height: 0;
  z-index: 6;
  max-height: 50vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #033858;
  padding: 2rem 3rem;
}

.categoriesMenuEmpty img{
  margin-bottom: 2rem;
}

.subMenuContent {
  box-shadow: 2px 3px 6px 2px #54565c3b;
  background-color: #ffff;
  border-radius: 0px 8px 8px 0px;
  padding-inline: 1.5rem;
  padding-block: 1.2rem;
}

.subMenuGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: start;
}

.listContent {
  color: #033858;
}

.listContent .listContentTopic:hover {
  color: #033858;
  background-color: #D9E1E6;
  cursor: pointer;
}

.listContent .listContentTopic {
  border-bottom: 1px solid transparent;
}

.listContentTopic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.categoryNames {
  color: #033858;
  text-align: center;
}

.categoryGrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.classIcon {
  height: 20px;
  width: 25px;
  margin-right: 0.6rem;
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg) brightness(100%) contrast(98%);
  transform: translateY(-2px);
}

.quickLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 25px;
  flex-wrap: wrap;
  overflow-y: hidden;
  padding-left: 2rem;
  height: 40px;
}

.quickLink {
  display: flex;
  height: 100%;
  align-items: center;
}

.categoryLink {
  font-weight: 500;
  color: #033858;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  border-bottom: 1px solid transparent;
}

.categoryLink:hover{
  border-bottom: 1px solid #033858;
}

.subCategories:hover {
  font-weight: 500;
  color: #033858;
}

.subCategory {
  border-bottom: 1px solid transparent;
  display: block;
}

.subCategory:hover {
  color: #033858;
  border-bottom: #0337585e 1px solid;
}

.subSubCategory:hover {
  font-weight: 500;
  color: #04738c;
  border-bottom: #04738c 1px solid;
}
