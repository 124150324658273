.orderContainer{
  margin-bottom: 165px;
}

.summary{
  background-color: #F2F5F6;
  color: #033858;
  border-radius: 16px;
  padding: 1rem;
  font-weight: 500;
  font-size: 1.02rem;
}

.approved{
  background-color: rgba(37, 179, 21, 0.75);
  border-radius: 8px;
  color: #ffff;
  padding: 0.1rem 0.5rem;
}

.notApproved{
  background-color: rgba(242, 159, 5, 0.75);
  border-radius: 8px;
  color: #ffff;
  padding: 0.1rem 0.5rem;
}

.tableContainer{
  border: 1px solid #868686;
  border-radius: 8px;
  margin-top: 1.5rem;
}

.tableTitle{
  text-align: center;
  padding-block: 0.5rem;
  font-weight: 500;
  font-size: 1.1rem;
}

.table{
  border: 1px solid #959595;
  border-radius: 8px;
}

.tableStyle{
  table-layout: fixed;
  width: 100%;
}

tr:last-of-type td{
  border-radius: 8px;
}

.productDescription{
  display: flex;
  flex-direction: column;
}


.descriptionStyle{
  font-weight: 500;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.tdStyles{
  border-bottom: 1px solid #959595;
  padding-block: 0.5rem;
  width: 100%;
  font-weight: 500;
}

.tdStyles td{
  text-align: center;
}

.tableContent{
  text-align: center;
}

.itemDescription{
  padding-left: 0.5rem;
}

.itemDescription div span{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ordersFormSelector label {
  color: var(--mainColor);
}

.ordersFormSelector select {
  background-color: #e6e9ea;
  border-radius: 8px;
  border: none;
  padding-block: 0.2rem;
  padding-inline: 1rem;
}

.orderForm {
  background-color: none !important;
  border-radius: 0px !important;
  padding: 0rem !important;
}

.orderBubble {
  border: 1px solid #c4ced4;
  border-radius: 8px;
  font-weight: 500;
}

.bubbleOpen {
  background-color: #e6e9ea;
}

.orderBubbleStatus {
  border-radius: 8px;
  padding-block: 0.2rem;
  padding-inline: 0.8rem;
  color: #f0f0f0;
  font-weight: 500;
  margin-bottom: 3px;
}

.bubbleStatusentregue {
  background-color: #5ccb58;
}

.bubbleStatusem_rota {
  background-color: #e8ae6d;
}

.bubbleStatuscancelado {
  background-color: #f15555;
}

.bubbleStatusaguardando {
  background-color: #3f8acb;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  text-align: center;
}

.orderBubbleSpans span {
  margin-bottom: 0.6rem;
}

.orderIcons {
  margin-inline: 1rem;
}

.orderIconsButtons {
  color: var(--mainColor);
  border: 1px solid #c4ced4;
  border-radius: 8px;
  padding-inline: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0px;
  width: 0;
  white-space: nowrap;
  padding-block: 10px;
}

/* RESUMO */

.summaryButton {
  background-color: var(--mainColor);
  color: #f0f0f0;
  border: none;
  padding-block: 0.8rem;
  padding-inline: 1.5rem;
  border-radius: 8px 8px 0 0;
}

.buttons{
  display: flex; 
  flex-direction: column;
  position: fixed;
  bottom: 62px;
  width: 100%;
  padding-bottom: 0.2rem;
}

.totalValue{
  display: flex;
  justify-content: space-between;
  background-color: #E6F1F4;
  padding: 1rem;
  color: #033858;
  font-weight: 600;
  font-size: 1.3rem;
}

@media screen and (max-width: 440px) {
  .summaryButton {
    padding-inline: 1rem;
  }
  .buttons{
    bottom: 56px;
  }
}
