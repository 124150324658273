.table td{
    border: 1px solid #000000;
}

/* TEMPLATE CSS */

@media print {
    @page {
        margin-inline: 0.5cm;
        margin-top: 0mm;
    }

    .a4Page{
        height: 297mm;
    }

    .footer {
        page-break-after: always;
    }
}

* {
    margin: 0;
}

.invoiceBody{
    background-color: #ffffff !important;
    color: #000000 !important;
}

.a4Page{
    position: relative;
}

.completeInvoice span{
    color: #000 !important;
}
.uiWidgetContent {
    border: none !important;
}

.nfeSquare {
    margin: 0 auto 2cm;
    box-sizing: border-box;
    width: 2cm;
    height: 1cm;
    border: 1px solid #000;
}

.nfeArea.page {
    width: 21cm;
    position: relative;
    color: #000;
    margin: 0 auto;
}

.nfeArea .font12 {
    font-size: 12pt;
}

.nfeArea .font8 {
    font-size: 8pt;
}

.nfeArea .bold {
    font-weight: bold;
}
/* == TABELA == */
.nfeArea .areaName {
    color: #000;
    font-weight: bold;
    margin: 5px 0 2px;
    font-size: 6pt;
    text-transform: uppercase;
}

.nfeArea .txtUpper {
    text-transform: uppercase;
    padding-top: 5px;
}

.nfeArea .txtCenter {
    text-align: center;
}

.nfeArea .txtRight {
    text-align: right;
}

.nfeArea .nfLabel {
    text-transform: uppercase;
    margin-bottom: 3px;
    display: block;
}

    .nfeArea .nfLabel.labelSmall {
        letter-spacing: -0.5px;
        font-size: 4pt;
    }

.nfeArea .info {
    font-size: 9.5pt;
    display: block;
    line-height: 1em;
}

.nfeArea table {
    color: #000;
    font-size: 6pt;
    border-collapse: collapse;
    width: 100%;
    border-color: #000;
}

.nfeArea .noTop {
    margin-top: -1px;
}

.nfeArea .mtTable {
    margin-top: 3px;
}

.nfeArea .valignMiddle {
    vertical-align: middle;
}

.nfeArea td {
    vertical-align: top;
    box-sizing: border-box;
    overflow: hidden;
    border-color: #000;
    border-width: 1px;
    padding: 4px;
    height: 5mm;
}

.nfeArea .tserie {
    width: 32.2mm;
    vertical-align: middle;
    font-size: 8pt;
    font-weight: bold;
}

    .nfeArea .tserie span {
        display: block;
    }

    .nfeArea .tserie h3 {
        display: inline-block;
    }

.nfeArea .entradaSaida .legenda {
    text-align: left;
    margin-left: 12mm;
    display: block;
}

    .nfeArea .entradaSaida .legenda span {
        display: block;
    }

.nfeArea .entradaSaida .identificacao {
    float: right;
    margin-right: 12mm;
    border: 1px solid black;
    width: 5mm;
    height: 5mm;
    text-align: center;
    padding-top: 0;
    line-height: 5mm;
}

.nfeArea .hrDashed {
    border: none;
    border-top: 1px dashed #444;
    margin: 5px 0;
}

.nfeArea .clientLogo {
    height: 16px !important;
    width: 58px;
    margin: 0.5mm;
}

.nfeArea .title {
    font-size: 10pt;
    margin-bottom: 2mm;
}

.nfeArea .txtc {
    text-align: center;
}

.nfeArea .pd0 {
    padding: 0;
}

.nfeArea .mb2 {
    margin-bottom: 2mm;
}

.nfeArea table table {
    margin: -1pt;
    width: 100.5%;
}

.boxDestinatario tbody tr td{
    border-bottom: none;
}

.nfeArea .wrapperTable {
    margin-bottom: 2pt;
}

    .nfeArea .wrapperTable table {
        margin-bottom: 0;
    }

        .nfeArea .wrapperTable table + table {
            margin-top: -1px;
        }

.nfeArea .boxImposto {
    table-layout: fixed;
}

    .nfeArea .boxImposto td {
        width: 11.11%;
    }

    .nfeArea .boxImposto .nfLabel {
        font-size: 6pt;
    }

    .nfeArea .boxImposto .info {
        text-align: right;
    }

    .boxImposto .taxesContent tr td {
        padding: 4px 0 4px 4px;
    }

    .boxImposto .taxesContent tr td .info {
        padding-right: 4px;
    }

.nfeArea .wrapperBorder {
    /* max-height: 75.7mm; */
}

    .nfeArea .wrapperBorder table {
        margin: 0 -1px;
        width: 100.4%;
    }

.nfeArea .contentSpacer {
    display: block;
    height: 10px;
}

.nfeArea .titles th {
    padding: 3px 0;
}

.boxProdutoServico thead tr th {
    padding-left: 2px !important;
}

.nfeArea .codigo {
    display: block;
    text-align: center;
}

.nfeArea .boxProdutoServico tr td:first-child {
    border-left: none;
}

.nfeArea .boxProdutoServico td {
    font-size: 9pt;
    height: auto;
}

.nfeArea .boxFatura span {
    display: block;
}

.nfeArea .boxFatura td {
    border: 1px solid #000;
}

.nfeArea .freteConta .border {
    width: 5mm;
    height: 5mm;
    float: right;
    text-align: center;
    line-height: 5mm;
    border: 1px solid black;
}

.nfeArea .freteConta .info {
    line-height: 5mm;
}

.boxFields{
    margin-inline: 6px;
    padding-block: 20px 10px;
}

.page .boxFields td p {
    font-size: 6pt;
    line-height: 1em;
    color: #000;
}

.nfeArea .imgCanceled {
    position: absolute;
    top: 75mm;
    left: 30mm;
    z-index: 3;
    opacity: 0.8;
    display: none;
}

.nfeArea.invoiceCanceled .imgCanceled {
    display: block;
}

.nfeArea .imgNull {
    position: absolute;
    top: 75mm;
    left: 20mm;
    z-index: 3;
    opacity: 0.8;
    display: none;
}

.nfeArea.invoiceNull .imgNull {
    display: block;
}

.nfeArea.invoiceCancelNull .imgCanceled {
    top: 100mm;
    left: 35mm;
    display: block;
}

.nfeArea.invoiceCancelNull .imgNull {
    top: 65mm;
    left: 15mm;
    display: block;
}

.nfeArea .pageBreak {
    page-break-before: always;
}

.nfeArea .block {
    display: block;
}

.labelMktup {
    font-family: Arial !important;
    font-size: 8px !important;
    padding-top: 8px !important;
}

.border-none{
    border: none;
}

.productBox{
    border-bottom: 1px dotted #000000;
}

.productBox td{
    padding-left: 2px;
    vertical-align: inherit;
}

.infoTime{
    font-size: 9pt;
    display: block;
    line-height: 1em;
}

.footer{
    position: relative;
    bottom: 0px;
    width: 21cm;
    margin: 0 auto;
}

.footer table{
    font-size: 6pt;
    border-collapse: collapse;
    width: 100%;
}

.footer .areaName{
    color: #000;
    font-weight: bold;
    margin: 5px 0 2px;
    font-size: 6pt;
    text-transform: uppercase;
}

.footer td{
    vertical-align: top;
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid #000;
    padding: 1px;
}

.footer td span{
    padding-left: 3px;
}

.totalButtons{
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
}

.downloadButton{
    display: flex;
    justify-content: center;
    background-color: #033858;
    color: #F0F0F0;
    padding: 1rem 2rem;
    border-radius: 16px;
    min-width: 170px;
    cursor: pointer;
}
.downloadButtonSpan{
    color: #F0F0F0 !important;
}