.dotSize {
  height: 8px;
  min-width: 8px;
  border-radius: 8px;
  margin-right: 6px;
}

.notificationsBottom {
  position: fixed;
  bottom: 60px;
  padding-block: 1rem;
  border: none;
  border-radius: 16px 16px 0 0;
  background-color: #53BF9D;
  color: #f0f0f0;
  font-weight: 500;
}

.image{ 
  max-width: 240px;
}

@media screen and (min-width: 500px) {
  .notificationsBottom {
    bottom: 68px;
  }
}