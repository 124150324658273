  .itemCompleteCounter{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .disabled {
    background-color: #EBEBE4 !important;
}

.labelDisabled {
    color: #BDBDBD !important;
}

  .hasBackground{
    background-color: #E6F1F4;
    width: 43px;
    border-radius: 0 8px 8px 0;
  }

  .addNew{
    border: none;
    border-radius: 8px;
    background-color: #E6F1F4;
    color: #033858;
    font-weight: 500;
    padding: 0.5rem;
  }

  .removeTrashOutsideButton{
    margin-right: 0.5rem;
    background-color: #E6F1F4;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    width: 42px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .removeTrashOutsideButton img{
    width: 20px;
    filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
    brightness(100%) contrast(98%);
  }

  .boxBadge{
    color: #033858;
    width: 42px;
    height: 40px;
    border-radius: 8px;
    position: relative;
    background-color: #E6F1F4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
  }

  .caixa{
    width: 26px;
  }

  .boxNumberBubble{
    position: absolute;
    background-color: #033858;
    padding-inline: 0.37rem;
    color: #f0f0f0;
    border-radius: 20px;
    font-size: 0.8rem;
    transform: translate(14px, -7px);
  }

  .addToCart{
    display: flex;
    align-items: center;
    padding: 0.2rem 0.3rem 0.1rem 0.3rem;
    gap: 0.2rem;
  }

  .addToCart img{
    transform: translateY(-1px);
  }

  .cardCounter{
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .add{
    background-color: #E6F1F4;
    border-radius: 0 8px 8px 0;
    border: none;
    font-size: 1.1rem;
    font-weight: 500;
    color: #033858;
    width: 42px;
    height: 40px;
  }

  .barCounter{
    color: #033858;
    text-align: center;
    border: none;
    background-color: #FFFFFF;
    font-weight: 500;
    font-size: 21px !important;
    width: 72px;
  }

  .remove{
    background-color: #E6F1F4;
    border-radius: 8px 0 0 8px;
    border: none;
    font-size: 1.25rem;
    font-weight: 500;
    color: #033858;
    width: 42px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .removeTrash{
    background-color: #E6F1F4;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    width: 42px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .removeTrash img{
    width: 16px;
  }

  .boxIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 8px;
    background-color: #E6F1F4;
    margin-left: 0.5rem;
    width: 42px;
    height: 40px;
  }

  .boxIcon img{
    width: 24px;
  }

  .boxCounter {
    display: flex;
    justify-content: center;
    position: absolute;
    background-color: #033858;
    color: white;
    font-size: 0.9rem;
    height: 17px;
    border: none;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 20px;
    font-feature-settings: 'pnum', "pnum";
    font-variant: proportional-nums;
    z-index: 3;
  }

  .boxCounterShort{
    min-width: 17px;
    transform: translate(30px, 5px);
  }

  .boxCounterLong{
    min-width: 22px;
    transform: translate(24px, 5px);
  }
