.ordersFormSelector label {
  color: var(--mainColor);
}

.ordersFormSelector {
  color: #033858;
  border-radius: 8px;
  border: none;
  padding-block: 0.2rem;
  padding-inline: 1rem;
  outline: none;
}

.ordersFormSelector img{
  background-color: #F2F5F6;
  padding: 0.5rem;
  border-radius: 8px;
}

.bottomSheetContent{
  display: flex;
  flex-direction: column;
  color: #033858;
  margin-bottom: 20px;
}

.bottomSheetContent input[type='radio']:checked{
  appearance: auto;
  background-color: #0669a5;
  accent-color: #0669a5;
}

.dateOption{
  display: flex; 
  align-items: center;
  padding-block: 0.5rem;
  box-shadow: 0 1px 0 rgba(46, 59, 66, calc(1 * 0.125));
  font-weight: 500;
}

.bottomSheetContent input[type='radio']:focus {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 0.95rem;
  height: 0.95rem;
  border: 0.25em solid #033858;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.bottomSheetContent input[type='radio']:focus + span {
  font-weight: 600;
}