.modalSize{
  /* max-height: 868px;
  min-height: 90vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 2rem 1rem;
}

/* .authenticationConfirmationSheet{
  scroll-behavior: unset;
  overflow: clip;
  height: 100%;
} */

.completeGrid {
  overflow-y: scroll;
  max-height: 710px;
}

.completeCardCategory {
  text-align: center;
  width: 128px;
  margin-bottom: 1.2rem;
}

.searchInput {
  border: none;
  border-bottom: 1px solid #819cac;
  width: 100%;
  padding-left: 2rem;
  padding-block: 0.7rem;
}

.searchInput:focus {
  border-bottom: 1px solid #033859;
}

.searchIcon svg{
  width: 18px;
  transform: translateX(7px) translateY(-37px);
}

.searchIcon img{
  width: 18px;
  transform: translateX(7px) translateY(-37px);
}

.searchInput:focus~.searchIcon {
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg) brightness(100%) contrast(98%);
}

.completeCategoryImage {
  border-radius: 16px;
  padding-block: 1rem;
}

.completeCardCategory:hover{
  background-color: #E6ECEF;
  border-radius: 8px;
}

.completeCardCategory:hover {
  font-weight: 500;
}

.loaderContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.categoryGrid{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.2rem;
  margin-block: 1.5rem;
  /* min-height: 544px; */
}

.pageCounterContainer{
  display: flex;
  justify-content: center;
  min-height: 56px;
}

.pageCounter{
  color: var(--mainColor);
  padding: 0.5rem;
  cursor: pointer;
  margin: 0.5rem;
}

.pageCounterActive{
  background-color: #D9E1E6;
  border-radius: 8px;
  padding: 0.1rem 0.9rem;
  display: flex;
  color: var(--mainColor);
  font-weight: 600;
  cursor: pointer;
  margin: 0.5rem;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .categoryGrid{
    grid-template-columns: 1fr 1fr 1fr;
  }
}
