.productBadge{
    display: flex;
    color: #f0f0f0;
    border-radius: 8px;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0.15rem 0.5rem;
    width: fit-content;
    white-space: nowrap;
    cursor: pointer;
    margin-inline: 0.2rem;
    margin-top: 4px;
    z-index: 5;
}

/* @media screen and (max-width: 500px) {
    .productBadge span{
        transform: translateY(1px);
    }
} */
