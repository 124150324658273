.modalBody{
    min-height: 468px;
    padding: 1.5rem;
}

.city{
    display: flex;
    align-items: center;
    color: #033858;
    text-decoration: underline;
}

.x{
    transform: scale(1.2) translateY(-8px);
    color: #033858;
}