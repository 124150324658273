.mobileAppLogo {
  pointer-events: none;
  height: 24px;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.marketName{
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--mainColor);
  margin-left: 0.6rem;
  display: flex;
  flex-direction: column;
}

.marketName span{
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 315px;
}

.notificationAlert{
  display: flex;
  position: absolute;
  background-color: var(--secondColor);
  color: white;
  width: 12px;
  height: 12px;
  border: 2px solid #f0f0f0;
  border-radius: 65%;
  font-feature-settings: 'pnum', "pnum", "pnum";
  font-variant: proportional-nums;
  transform: translate(8px, 14px);
  justify-content: center;
}

.delivery div{
  font-weight: 400;
  position: relative;
}

.marketInfoContainer{
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}

.profileIcon{
  transform: translateY(-2px);
}

.sticky{
  position: sticky;
  top: 0;
  z-index: 5;
}

.stickySearch{
  position: sticky;
  top: 22px;
  z-index: 5;
  background-color: #ffff;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.fixedDeliveryContainer{
  margin-top: 24px;
}

.fixedSearch {
  position: fixed;
  width: 100%;
  top: 29px;
  z-index: 5;
  background-color: #ffff;
}

.cityName span{
  font-weight: 400;
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .fixedSearch {
    top: 22px;
  }
}

@media screen and (max-width:440px) {
  .delivery div span{
    font-size: 0.8rem;
  }
}
