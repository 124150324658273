.suggestedImage{
    width: 80px;
    height: 80px;
    border: 1px solid #b7b7b7;
    border-radius: 8px;
}

.suggestedDescription{
    padding-left: 1rem;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}