.rdt_TableHead{
  border: 1px solid #C0CDD5;
  border-bottom: none;
  border-radius: 16px 16px 0px 0px !important;
}

.rdt_TableHeadRow {
  background-color: #F2F5F6 !important;
  font-size: 1rem !important;
  color: var(--mainColor) !important;
  border-radius: 16px 16px 0px 0px;
}

.dKXskp:not(:last-of-type){
  border-bottom-style: none !important;
  border-bottom-width: 0px !important;
  border-bottom-color: rgba(0, 0, 0, 0) !important;
}

.rdt_TableRow {
  font-size: 1rem !important;
  border-bottom: none !important;
  border-radius: 0px 0px 16px 16px !important;
  padding-block: 1.5rem;
}

.rdt_TableRow div{
  display: flex;
  justify-content: center;
}

.rdt_TableCell {
  border: none !important;
  text-align: center;
}

.rdt_TableBody{
  border: 1px solid #C0CDD5;
  border-top: none;
  border-radius: 0px 0px 16px 16px !important;
}

.rdt_TableCell button {
  border: none;
  text-decoration: underline;
  background-color: #FFFFFF;
}

.rdt_ExpanderRow {
  background-color: #FFFFFF !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.rdt_TableCol_Sortable {
  justify-content: center !important;
}

.orderbadgeStatus {
  border-radius: 8px;
  padding-block: 0.2rem;
  padding-inline: 0.6rem;
  font-weight: 500;
}

.badgeStatusentregue {
  border:1px solid #66B169;
  color: #66B169
}

.badgeStatusaprovado {
  border:1px solid #66B169;
  color: #66B169
}

.badgeStatusativo {
  border:1px solid #66B169;
  color: #66B169
}

.badgeStatus4 {
  border:1px solid #66B169;
  color: #66B169
}

.badgeStatusdisponivel {
  border:1px solid #66B169;
  color: #66B169
}

.badgeStatusconcluded {
  border:1px solid #66B169;
  color: #66B169
}

.badgeStatusem_rota {
  border: 1px solid #E7BD6B;
  color: #E7BD6B;
}

.badgeStatusvencido {
  border: 1px solid #E7BD6B;
  color: #E7BD6B;
}

.badgeStatuscancelado {
  border: 1px solid #F98F88;
  color: #F98F88;
}

.badgeStatusaguardando {
  border: 1px solid #5A93F7;
  color: #5A93F7;
}

.badgeStatus3 {
  border: 1px solid #5A93F7;
  color: #5A93F7;
}

.badgeStatuscadastrado {
  border: 1px solid #5A93F7;
  color: #5A93F7;
}

.badgeStatususado {
  border: 1px solid #5A93F7;
  color: #5A93F7;
}

.badgeStatuspendente{
  border: 1px solid #5A93F7;
  color: #5A93F7;
}