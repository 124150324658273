.bubble {
  min-height: 120px;
  display: flex;
  align-items: stretch;
}

.noListContainer{
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.newButton{
  margin-top: 1rem;
  font-weight: 500;
  color: #033858;
  background-color: #F2F5F6;
  border-radius: 8px;
  padding: 1rem;
}

.bubbleNumber {
  background-color: #c0dce2;
  border: 1px solid #c0cdd5;
  border-radius: 16px 0 0 16px;
  padding-block: 2rem;
  padding-inline: 1rem;
  color: var(--mainColor);
  font-size: 1.4rem;
  font-weight: 600;
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bubbleText {
  border: 1px solid #c0cdd5;
  border-radius: 0 16px 16px 0;
  padding: 2rem;
  color: var(--mainColor);
  width: 86%;
  display: flex;
  align-items: center;
}

.listIcon {
  vertical-align: revert;
}

.boxBubble {
  background-color: #c0dce2;
  color: var(--mainColor);
  font-weight: 500;
  border-radius: 4px;
  font-size: 0.9rem;
  padding-inline: 0.5rem;
  padding-block: 0.1rem;
  width: fit-content;
}

.trashCan {
  font-size: 1.6rem;
  color: #7994a4;
  padding-right: 0.5rem;
  transform: translate(0px, -4px);
}

.trashCan :hover {
  color: var(--mainColor);
}

.listBubble{
  display: flex;
  justify-content: space-between;
  background-color: #F2F5F6;
  border: 1px solid #C0CDD5;
  border-radius: 8px;
  padding: 1rem 0.5rem;
  color: #033858;
  font-weight: 500;
}

.addButton{
  position: fixed;
  bottom: 59px;
  width: 100%;
  border-radius: 16px 16px 0 0;
  padding: 1rem;
  background-color: #033858;
  color: #ffff;
  font-weight: 500;
  text-align: center;
}