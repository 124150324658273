.btnPrimary {
  border: none;
  padding: 0px;
  border-radius: 16px;
  background-color: #dbe0e3;
}

.discount {
  font-size: 0.8rem;
  text-decoration: line-through;
  font-weight: 500;
  margin: 0px;
  padding-top: 0.4rem;
  color: var(--mainColor);
}

.price {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0px;
  padding-bottom: 0.4rem;
  color: var(--mainColor);
}

.btnBoxSelected {
  display: flex;
  color: #033858 !important;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 8px;
  padding: 0px;
  font-weight: 600 !important;
  background-color: #E6F1F4 !important;
  border-bottom: none !important;
}

.btnBoxSelected img {
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
    brightness(100%) contrast(98%) !important;
}

.btnItemSelected {
  display: flex;
  color: #033858 !important;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 8px;
  padding: 0px;
  font-weight: 600 !important;
  background-color: #E6F1F4 !important;
  border-bottom: none !important;
}

.btnItemSelected img {
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
    brightness(100%) contrast(98%) !important;
}

.btnItemFaded {
  display: flex;
  color: #033858;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 8px;
  font-weight: 500;
  padding-block: 0.5rem;
  text-align: center;
  border-bottom: 1px solid #C5DADF;
}

.btnItemFaded img {
  filter: invert(66%) sepia(13%) saturate(11%) hue-rotate(325deg) brightness(97%) contrast(84%);
}

.btnBoxFaded {
  display: flex;
  color: #033858;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 8px;
  font-weight: 500;
  padding-block: 0.5rem;
  text-align: center;
  border-bottom: 1px solid #C5DADF;
}

.btnBoxFaded img {
  filter: invert(66%) sepia(13%) saturate(11%) hue-rotate(325deg) brightness(97%) contrast(84%);
}

.unitPricePosition{
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priceMob {
  font-size: 1.6rem;
}

.cesta{
  width: 18px;
  margin-right: 0.5rem;
  transform: translateY(-2px);
}

.caixa{
  width: 18px;
  transform: translateY(-2px);
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg) brightness(100%) contrast(98%) !important;
}