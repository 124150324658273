/* PAYMENTPAGE */

.addressContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #c2c2c2;
  margin-block: 1rem;
  border-radius: 4px;
  padding-block: 1rem;
}

.paymentCupom label {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  color: var(--mainColor);
}

.paymentCupom input {
  padding-block: 0.3rem;
  border: 1px solid #7491a1;
  background-color: #f0f0f0;
  border-radius: 4px;
  text-transform: uppercase;
  flex-grow: 1;
}

.paymentBottomLine {
  padding-bottom: 0.3rem;
}

.paymentCupomInput {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

.paymentCupomInput button {
  background-color: #04738c;
  border-radius: 4px;
  font-weight: 600;
  font-size: large;
  color: white;
  border: none;
  padding: 0.5rem;
  padding-inline: 1rem;
  margin-left: 2rem;
}

.paymentDonation {
  display: flex;
  flex-direction: column;
  color: #033858;
}

.paymentDonation input {
  padding-block: 0.3rem;
  border: 1px solid #7491a1;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.observations{
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;
  height: 9rem;
  margin-top: 1rem;
}

.supportButton{
  background-color: #033858;
  color: #ffff;
  border-radius: 4px;
  font-size: 1.4rem;
  padding: 0.5rem;
  margin-block: 0.8rem;
}

.whatsappIcon{
  height: 1.5rem;
  filter: brightness(0) saturate(100%) invert(87%) sepia(94%) saturate(0%) hue-rotate(353deg) brightness(105%) contrast(105%);
  margin-right: 0.2rem;
  transform: translateY(-1px);
}

.backButton{
  width: 100%;
  border: none;
  border-radius: 6px;
  background-color: #D61C4E;
  color: #ffff;
  font-weight: 500;
  margin-top: 0.5rem;
  padding: 0.8rem;
}

.paymentFinalPrices {
  background-color: #F2F5F6;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.paymentFinalTotal {
  background-color: #D9E1E6;
  font-weight: 600;
  color: var(--mainColor);
  border-radius: 4px;
}

.paymentDonation label {
  font-weight: 600;
  color: var(--mainColor);
}

.noDonationCheckbox {
  font-weight: 400 !important;
}

.paymentDonationRadios {
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 90px 90px 90px; */
  justify-content: space-between;
  padding-inline: 1rem;
  row-gap: 1rem;
  column-gap: 0.8rem;
  margin-top: 1rem;
}

.warningIcon{
  height: 14px;
  filter: invert(0%) sepia(89%) saturate(14%) hue-rotate(100deg) brightness(20%) contrast(100%);
  transform: translateY(2px);
  margin-right: 0.2rem;
}

.invoiceButton{
  display: flex;
  justify-content: center;
  row-gap: 1rem;
  margin-bottom: 1rem;
}

.donationBtn{
  padding: 0.55rem 0.2rem 0.5rem 0.2rem;
}

.donationBtn:has(input:checked) {
  padding: 0.55rem 0.8rem 0.5rem 0.8rem;
  background-color: #F2F5F6;
  border-radius: 4px;
  color: var(--mainColor);
}

.donationBtnDisabled{
  color: #7491a1c0;
  cursor: default;
}

.donationBtnDisabled label {
  color: #819cac;
}

.donationBtn input[type='radio']:checked {
  background-color: #033858;
  accent-color: #033858;
}

.donationBtn input[type='radio']:hover {
  background-color: #033858;
  accent-color: #06619a;
}


input[type='radio']{
  width: 0.85rem;
  height: 0.85rem;
  transform: translateY(-1px);
}

.paymentMethods {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  border: 1px solid #C0CDD5;
  border-radius: 4px;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
}

.activeContent {
  display: block;
}

.blocTabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #033858;
  border-radius: 4px;
  padding: 0.1rem 0rem 0rem 0rem;
  text-align: center;
  margin: 0.2rem;
  font-size: 0.9rem;
  font-weight: 500;
  border: 1px solid transparent;
}

.activeTab {
  background-color: #F2F5F6;
  border: 1px solid var(--mainColor);
}

.tabs span {
  font-size: 1rem;
  padding-right: 1rem;
}

.paymentDescription{
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.boletoSelector{
  width: 100%;
  padding: 0.4rem;
  border: 1px solid #868686;
  border-radius: 8px;
  background-color: #ffff;
  font-weight: 500;
}

.fadedCard {
  position: absolute;
  right: 2rem;
  padding-top: 12px;
}

.deliveryAddress{
  border: 1px solid #C0CDD5;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.deliveryValues{
  color: #033858;
  font-weight: 500;
  margin-bottom: -5px;
}

.deliveryWarning{
  background-color: #F2F5F6;
  border-radius: 8px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deliveryWarning span{
  text-align: center;
}

.deliveryWarning img{
  width: 50px;
  margin-bottom: 0.5rem;
}

.deliveryExtimation{
  background-color: #F2F5F6;
  color: #033858;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 8px;
}

.addCartArea{
  display: flex;
  flex-direction: column;
  background-color: #F2F5F6;
  padding: 1rem 2rem;
  align-items: center;
  border-radius: 8px;
}

.addCartArea span{
  color: #033858;
}

.addCardButton{
  border-radius: 8px;
  border: 2px solid #033858;
  color: #033858;
  font-weight: 500;
  padding: 0.2rem 1.5rem;
  margin-top: 0.5rem;
}

.submitBtn {
  background-color: #53bf9d;
  color: #f0f0f0;
  padding: 1rem 0 1.5rem 0;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0 0;
  font-weight: 500;
  margin-bottom: 46px;
  width: 100%;
}

.submitBtn.submitBtnDisabled{
    background-color: #c4ced4;
}

.noDonation{
  transform: translateY(1px);
  margin: 1rem 0.2rem 0 1rem;
}

.confirmationStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmationButtons {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  margin-block: 2rem;
}

.paymentAlert {
  background-color: #c4ced4;
  border-radius: 4px;
  color: #033858;
  padding-block: 0.4rem;
  padding-inline: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.pixArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addCouponButton{
  color: var(--mainColor);
  border-radius: 4px;
  background-color: #F2F5F6;
  font-weight: 600;
  padding: 0.4rem 0.6rem;
}

@media screen and (max-width: 1080px) {
  .tabs-desktop span svg {
    font-size: 2rem;
  }

  .tabs-desktop {
    font-size: 1rem;
  }
}

@media screen and (max-width: 470px) {
  .paymentDonationRadios {
    row-gap: 0rem;
    column-gap: 0rem;
    padding-inline: 0rem;
  }
}

/* CARD */

.cardName {
  display: flex;
  justify-content: space-around;
}

.cardNumbers {
  display: flex;
  justify-content: space-around;
}

.cardInstallments {
  display: flex;
  flex-direction: column;
}

.cardBubble{
  background-color: #F2F5F6;
  border-radius: 8px;
  align-items: center;
  padding: 0.5rem 1.5rem;
  display: flex;
  margin-bottom: 0.5rem;
}

.cardBubble input[type='radio']{
  appearance: auto;
  background-color: #033858;
  accent-color: #033858;
}

.cardBubble input[type='radio']:focus{
  appearance: auto;
  background-color: #033858;
  accent-color: #033858;
}

.cardBubbleContent{
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
  margin-left: 1rem;
}

.selectInstances{
  border-radius: 8px;
  border: 1px solid #868686;
  color: #868686;
  padding: 0.5rem 1rem;
}

/* CHECKOUT FINISH */

.approvedBtn {
  background-color: #033858;
  color: #f0f0f0;
  border: none;
  border-radius: 4px;
  padding-block: 0.5rem;
  padding-inline: 1.5rem;
  margin-block: 1rem;
}

.approvedBtnOk {
  background-color: #53bf9d;
}

.approvedBtnDeny {
  background-color: #d61c4e;
}

.form-control{
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
}

.formCheckInput input[type='radio']{
  accent-color: #033858;
  transform: translateY(0px);
}

.cardBubble input[type='radio']:hover{
  background-color: #033858;
  accent-color: #06619a;
}

.formCheckInput input[type='radio']:checked{
  appearance: auto;
  background-color: #033858;
  accent-color: #033858;
}

.trackingButton{
  background-color: #F2F5F6;
  border-radius: 4px;
  color: #033858;
  font-weight: 600;
  padding: 0.5rem 1rem;
  text-align: center;
  margin-bottom: 1rem;
}

.warningText{
  display: flex;
  font-size: 0.8rem;
  line-height: 14px;
  color: #4A4A4A;
}