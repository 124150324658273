.footerContainer{
    margin-top: 5rem;
  }
  
  .footerStorefront {
    color: var(--mainColor);
    justify-content: center;
    background-color: #F2F5F6;
    align-items: center;
    padding-block: 2rem;
  }
  
  .logoInfo p {
    font-weight: 500;
  }
  
  .appStore{
    width: 120px;
    height: 50px;
    cursor: pointer;
  }
  
  .footerLogo {
    margin-bottom: 1rem;
  }
  
  .footerLogoSmall {
    height: 45px;
    margin-bottom: 1rem;
  }
  
  .navegueLinks {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .categoriasLinks {
    display: flex;
    flex-direction: column;
  }
  
  .footerStorefrontLink {
    text-decoration: underline !important;
    color: var(--mainColor);
    margin-block: 0.5rem;
  }
  
  .contatoLinks {
    height: 100%;
  }
  
  .contatoLinks span {
    font-weight: 500;
  }
  
  .footerLinksContainer {
    display: flex;
    justify-content: space-around;
  }
  
  .botButton{
    border: 1px solid #033858;
    border-radius: 8px;
    font-weight: 500;
    color: #033858;
    background-color: #F2F5F6;
    padding: 0.5rem 1.5rem;
    white-space: nowrap;
  }
  
  .footerEnd {
    background-color: var(--mainColor);
    text-align: center;
    padding-block: 0.5rem;
  }
  
  .footerEnd span {
    color: #f0f0f0;
    padding-block: 0.2rem;
    font-weight: 300;
  }
  
  .adress {
    justify-content: center;
    max-width: 300px;
    flex-direction: column;
    align-items: flex-start;
  }
  