.ordersFormSelector label {
    color: var(--mainColor);
}

.ordersFormSelector select {
    background-color: #e6e9ea;
    color: #033858;
    border-radius: 8px;
    border: none;
    padding-block: 0.2rem;
    padding-inline: 1rem;
}

.ordersFormSelector select:focus-visible {
    outline: none;
}

.orderForm {
    background-color: none !important;
    border-radius: 0px !important;
    padding: 0rem !important;
}

.searchbar{
    position: relative;
}

.searchInput {
    border: none;
    background-color: #F2F5F6;
    border-bottom: 1px solid transparent;
    border-radius: 4px;
    width: 100%;
    padding-left: 2rem;
    padding-block: 0.5rem;
  }
  
  .searchInput:focus {
    border-bottom: 1px solid #033859; 
  }

  .searchIcon{
    position: absolute;
    top: 6px;
    left: 6px;
  }

  .searchIcon svg{
    transform: translateX(6px) translateY(-29px) scale(1.5);
  }
  
  .searchIcon img{
    width: 18px;
  }
  
  .searchInput:focus~.searchIcon {
    filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg) brightness(100%) contrast(98%);
  }

/* RASTREIO */

.rastreioStep {
    color: #033858;
    font-weight: 500;
}

.rastreioSituação {
    font-weight: 500;
    color: #033858;
    font-size: 1.2rem;
}

.iconRastreioDone {
    background-color: #e5e5e5;
    color: #afafaf !important;
    border-radius: 35px;
    width: 60px;
    height: 60px;
    margin: 1rem;
    padding: 10px;
    z-index: 2;
}

.iconRastreioActive {
    background-color: #c9e4db;
    color: var(--mainColor);
    border-radius: 35px;
    margin: 1rem;
    width: 60px;
    height: 60px;
    padding: 10px;
    z-index: 2;
}

.iconRastreioUpcoming {
    background-color: #b5d1d7;
    color: var(--mainColor);
    border-radius: 35px;
    margin: 1rem;
    width: 60px;
    height: 60px;
    padding: 10px;
    z-index: 2;
}

.rastreioLine {
    width: 10px;
    border: 1px dashed #033859;
    transform: rotate(-90deg);
    padding-left: 5.5rem;
    z-index: 1;
}

/* FATURA */

.faturaButton {
    background-color: #033858;
    color: #f0f0f0;
    border: none;
    padding-inline: 2rem;
    padding-block: 0.5rem;
    margin-block: 1rem;
    border-radius: 8px;
}

.faturaLine {
    border-bottom: 1px solid #d9d9d9;
}

.faturaHighlight {
    color: var(--mainColor);
    font-weight: 600;
    margin-top: 0.8rem;
}

.ordersFormSelector label {
    color: var(--mainColor);
}

.ordersFormSelector {
    color: #033858;
    border-radius: 8px;
    border: none;
    padding-block: 0.2rem;
    padding-inline: 1rem;
    outline: none;
}

.ordersFormSelector img {
    background-color: #F2F5F6;
    padding: 0.5rem;
    border-radius: 8px;
}
