.listed {
  /* filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg) brightness(100%) contrast(98%); */
  width: 22px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.listed:hover {
  filter: invert(70%) sepia(0%) saturate(131%) hue-rotate(150deg) brightness(77%) contrast(78%);
}

.notListed {
  filter: invert(81%) sepia(27%) saturate(96%) hue-rotate(159deg) brightness(350%) contrast(55%);
  width: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
}
