.pageContent{
  margin-bottom: 100px;
}

.listSummary{
  background-color: #F2F5F6;
  border-radius: 16px;
  padding: 1rem;
  margin-block: 1rem;
}

.listFooterBar{
    position: fixed;
    bottom: 61px;
    left: 0;
    right: 0;
    z-index: 2;
  }

.listFooterBarWithCart{
    position: fixed;
    bottom: 115px;
    left: 0;
    right: 0;
    z-index: 2;
}

.itemGroupName{
    font-weight: 500;
    color: #033858;
    font-size: 1.1rem;
}

.chevronDown{
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}

.chevronUp{
  transition: transform 0.3s ease;
}

.chevron {
  animation: rotate180 0.3s ease;
}

.cartItemClassButton{
  border: none;
  color: var(--mainColor);
  background-color: #E6F1F4;
  border-radius: 4px;
  font-weight: 600;
}

.backBtn{
  background-color: #033858;
  color: #f0f0f0;
  padding: 4px 16px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
}

.excludeListButton {
  border-radius: 16px 16px 0 0;
  padding: 0.8rem 1rem;
  background-color: #D61C4E;
  color: #f0f0f0;
  font-weight: 500;
}

.exitTrash{
  height: 14px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(28deg) brightness(122%) contrast(88%);
  transform: translateY(-2px);
  margin-left: 0.5rem;
}

/* FOOTBAR */

.sheetContent{
  display: flex; 
  justify-content: center; 
  align-items: center;
}

.addListButton {
  color: #f0f0f0;
  background-color: #53bf9d;
  border-radius: 16px 16px 0 0;
  padding: 0.8rem 1rem;
  font-weight: 500;
}

.addButtonDisabled{
  display: flex;
  align-items: center;
  color: #868686;
  background-color: #E7E7E7;
  border-radius: 16px 16px 0 0;
  padding: 0.6rem 1rem;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  font-weight: 500;
}

.bottomSheet {
  text-align: center;
  padding-inline: 1rem;
}

.bottomsheetTrashing div:nth-child(2) div:nth-child(1)::before {
  display: none;
}

.bottomSheetAddCarrinho div:nth-child(2) div:nth-child(1)::before {
  display: none;
}

/* [data-rsbs-overlay]{
    --rsbs-overlay-h: 160px;
} */

.buttonFaded {
  border: none;
  border-radius: 8px;
  color: #868686;
  font-weight: 500;
  font-size: 1.2rem;
  padding-block: 0.3rem;
  padding-inline: 1.2rem;
  background-color: #dbdbdb;
  margin-inline: 0.5rem;
  outline: none;
}

.buttonDelete {
  border: none;
  border-radius: 8px;
  color: #f0f0f0;
  font-weight: 500;
  font-size: 1.2rem;
  padding-block: 0.3rem;
  padding-inline: 1.2rem;
  background-color: #d61c4e;
  margin-inline: 0.5rem;
}

.buttonAddToCart {
  border: none;
  border-radius: 8px;
  color: #f0f0f0;
  font-weight: 500;
  font-size: 1.2rem;
  padding-block: 0.3rem;
  padding-inline: 1.2rem;
  background-color: #53bf9d;
  margin-inline: 0.5rem;
}

.input {
  border-radius: 8px;
  border: 1px solid #819cac;
  min-width: 80%;
  margin-top: 0.5rem;
  padding-block: 0.2rem;
  padding-left: 1rem;
}

@media screen and (max-width: 500px) {
  .listFooterBarWithCart{
    bottom: 105px;
  }
}

@media screen and (max-width: 412px) {
  .listFooterBarWithCart{
    bottom: 98px;
  }
}

@media screen and (max-width: 323px) {
  .addListButton {
    padding: 0.8rem 0.6rem;
  }
  .excludeListButton {
    padding: 0.8rem 0.6rem;
  }
}