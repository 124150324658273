.page{
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.button{
    border-radius: 4px;
    background-color: #EEF9F6;
    color: #033858;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
}

.supportLink{
    font-weight: 600;
    text-decoration: underline;
}