.search {
    position: relative;
}

.formField {
    position: relative;
    display: inline-block;
    margin-top: 1rem;
}

.searchIconFaded {
    position: absolute;
    left: 12px;
    filter: invert(80%) sepia(22%) saturate(65%) hue-rotate(75deg) brightness(90%) contrast(87%);
    transform: translateY(1px);
    font-size: 1.8rem;
    z-index: 3;
}

.xIcon {
    position: absolute;
    right: 12px;
    bottom: 0px;
    font-size: 1.8rem;
    z-index: 3;
}

.searchBar {
    font-size: 22px;
}

.search input {
    border: none;
    border-bottom: 1px solid #819CAC;
    font-size: 1.2rem !important;
    height: 3rem;
    width: 100%;
    padding-left: 2.5rem;
    z-index: 2;
}

.searchInputs {
    display: flex;
    flex-direction: row-reverse;
}

.locationButton {
    display: flex;
    justify-content: center;
    color: #033858;
    background-color: #F2F5F6;
    font-weight: 600;
    border-radius: 8px;
    padding: 1rem;
    margin-block: 1.2rem;
    cursor: pointer;
}
