.topbar{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #033858;
    padding: 0.4rem 4rem;
}

.header{
    display: flex; 
    justify-content: space-between; 
    background-color: #F2F5F6;
    align-items: center; 
    padding-block: 0.5rem;
}

.headerContent div{
    font-weight: 500;
    color: #033858;
    border-bottom: 1px solid transparent;
    display: flex;
    align-items: center;
}

.header div :hover:not(:first-child){
    border-bottom: 1px solid #033858;
    cursor: pointer;
}
