.container{
  display: flex;
  justify-content: space-between;
  border: 1px solid #868686;
  border-radius: 8px;
  cursor: pointer;
}

.changeContainer{
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
  background-color: #F3F3F3;
}

.addNew{
  display: flex;
  justify-content: space-evenly;
  padding: 7px 16px;
}

.addNew span{
  font-weight: 500;
}

.counterButton{
  font-size: 1.5rem;
  text-decoration: none !important;
  background-color: transparent;
  border: none;
  padding-inline: 1rem;
}

.counterChangeButton{
  font-size: 1.5rem;
  text-decoration: none !important;
  background-color: transparent;
  border: none;
  padding-inline: 1rem;
  background-color: #E6E9EA;
}

.counterChangeButton:first-child{
  border-radius: 8px 0 0 8px;
}
.counterChangeButton:last-child{
  border-radius: 0 8px 8px 0;
}

.counterChangeButton:hover{
  background-color: #C2C2C2;
}

.numberField{
  text-align: center;
  max-width: 60px;
  border: none;
}

.numberChangeField{
  background-color: #F3F3F3;
  text-align: center;
  max-width: 60px;
  border: none;
}

.boxBadge{
  color: #868686;
  font-weight: 400;
  font-size: 0.9rem;
}

.boxBadge span{
  position: absolute;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
  }