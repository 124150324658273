:root {
  --rsbs-backdrop-bg: rgba(3, 10, 47, 0.2);
}

[data-rsbs-scroll] {
  overflow: hidden;
}

.orderBubble {
  border: 1px solid #c4ced4;
  border-radius: 8px;
  font-weight: 500;
}

.orderBubbleCard {
  border-radius: 8px;
  padding-block: 0.2rem;
  padding-inline: 1rem;
  color: #f0f0f0;
  font-weight: 500;
}

.cardRemoveButton {
  background-color: #e7e7e7;
  color: #868686;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #cecece;
}

.bubbleStatusAtivo {
  background-color: #5ccb58;
  max-width: 70px;
}

.bubbleStatusCancelado {
  background-color: #d61c4e;
}

.bubbleStatusCadastrado {
  background-color: #3f8acb;
}

.cardBoldCapital {
  text-transform: uppercase;
  font-weight: 500;
}

.cardThinInfo {
  font-weight: 300;
}

.deletionTitle {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.creditCardDeletion {
  border-radius: 4px 4px 0 0;
  font-size: 1.2rem;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  width: 100%;
  border: none;
}

.creditCardDeletionExclude{
  border-radius: 4px;
  padding-inline: 1.2rem;
  border: none;
}

.deletionNo {
  background-color: #bac8cc;
  color: #646b6d;
}

.deletionYes {
  background-color: #d61c4e;
  color: #f0f0f0;
}

.deletionSave {
  background-color: #53bf9d;
  color: #f0f0f0;
}

.changeNicknameModal {
  background-color: #FFFFFF;
  padding-bottom: 1rem;
}

.nicknameNewTitle {
  font-weight: 500;
}

.changeNicknameInput {
  width: 100%;
  border: 1px solid #b7b7b7;
  border-radius: 4px;
  padding: 0.4rem 0.2rem;
  
}

.cardPencilIcon {
  font-size: 1.5rem;
  color: #7994a4;
  z-index: 3;
}

.changeNicknameTitle {
  display: flex;
  justify-content: space-between;
  padding-inline: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  width: 100%;
}

.bottomsheet div:nth-child(2) {
  display: block;
}

.formControl {
  border-radius: 4px;
  border: 1px solid #868686;
}

.formControl:focus-visible {
  outline: 1px solid #868686 !important;
}

.addButton{
  position: fixed;
  bottom: 58px;
  display: flex;
  justify-content: center;
  padding-block: 1rem 1.1rem;
  border-radius: 16px 16px 0 0;
  background-color: #033858;
  color: #f0f0f0;
  width: 100%;
}