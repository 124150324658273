@import url('https://fontawesome.com/icons/angle-down?s=solid&f=classic');

.faqTitle {
  color: #033858;
  background-color: #F2F5F6;
  font-weight: 600;
  font-size: 1.2rem;
  padding-block: 1rem;
  border-radius: 8px;
}

.faqContentTitle {
  font-size: 1.1rem;
  font-weight: 400;
}

.openSings{
  background-color: #F2F5F6;
  color: #033858;
  font-weight: 600;
  font-size: 1.3rem;
  border-radius: 4px;
  padding-inline: 0.5rem;
  margin-right: 0.5rem;
}

/* ANIMATED CHEVRON  */

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding-right: 25px;
}

.Collapsible__trigger::after {
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  transition: transform 300ms;
  border-style: solid;
  border-width: 0.12em 0.12em 0 0;
  content: '';
  display: inline-block;
  height: 0.45em;
  top: 0.5em;
  transform: rotate(135deg);
  vertical-align: top;
  width: 0.5em;
  margin-left: 1rem;
}

.is-open::after {
  transform: rotateZ(315deg);
}
