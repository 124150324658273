.listCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
}

.listCard span{
  font-weight: 500;
}

.listCard > img{
  height: 96px;
  width: 96px;
}

.editButton {
  width: 20px;
  cursor: pointer;
  margin-left: 6px;
  transform: translateY(-1px);
}

.trashCanList {
  color: var(--mainColor);
  background-color: #F2F5F6;
  transform: translateY(5px);
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-right: 0.4rem;
  padding: 4px 8px;
}

.trashCanList:hover {
  background-color: #C9CBCC;
  cursor: pointer;
}

.listButton{
  color: #033858;
  background-color: #F2F5F6;
  border-radius: 8px;
  padding: 0.2rem 1rem;
  font-weight: 500;
  margin-top: 0.6rem;
}

.listButton:hover {
  background-color: #C9CBCC;
  cursor: pointer;
}

.listButton img{
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
  brightness(100%) contrast(98%);
  margin-left: 0.5rem;
}
