.active {
  position: absolute;
  top: -5px;
  right: 15px;
}

.smallIcon{
  min-width: 46px;
}

.profileIcon {
  font-size: 2.5rem;
  margin-right: 1rem;
}
