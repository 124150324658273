.footbar {
  width: 100%;
  display: block;
  bottom: 58px;
  z-index: 5;
}

.footbarBack {
  background-color: var(--mainColor);
  border-radius: 8px 8px 0 0;
  padding-block: 0.4rem 0.6rem;
}

.footbarInfo img {
  height: 18px;
}

.footbarInfo p {
  margin-bottom: 0px;
  color: white;
  font-weight: 600;
}

.priceBubble {
  color: #f0f0f0;
  font-weight: 600;
  display: flex;
}

.priceBubbleOld {
    color: #999999;
    font-weight: 400;
    text-decoration: line-through;
  }
