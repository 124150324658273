.orderBubble {
  border: 1px solid #c4ced4;
  border-radius: 8px;
  font-weight: 500;
}

.bubbleContainer{
  width: 29%;
}

.orderBubbleTicket {
  border-radius: 8px;
  padding-block: 0.2rem;
  padding-inline: 0.5rem;
  color: #f0f0f0;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: block;
  text-align: center;
}

.bubbleContainerFull{
  border-radius: 8px;
  color: #f0f0f0;
  font-weight: 500;
}

.ticketBottom {
  background-color: #F2F5F6;
  color: var(--mainColor);
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 0 0 8px 8px;
}
