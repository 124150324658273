.containerLong{
    display: flex;
    justify-content: space-between;
    min-height: 56px;
    min-width: 450px;
}

.containerShort{
    display: flex;
    justify-content: center;
    min-height: 56px;
}

.pageCounter{
    color: var(--mainColor);
    padding: 0.5rem;
    cursor: pointer;
    margin: 0.5rem;
    font-weight: 500;
}

.pageCounterDisabled{ 
    filter: invert(70%) sepia(0%) saturate(131%) hue-rotate(150deg) brightness(77%) contrast(78%);
    padding: 0.5rem;
    margin: 0.5rem;
    font-weight: 500;
}

.pageCounterActive{
    background-color: #D9E1E6;
    border-radius: 8px;
    padding: 0.1rem 0.9rem;
    display: flex;
    color: var(--mainColor);
    font-weight: 600;
    cursor: pointer;
    margin: 0.5rem;
    align-items: center;
  }