* {
  font-family: 'Roboto', sans-serif;
}

:root body {

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* header.position {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  background-color: #e6e9ea;
}

header.sticky {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #FFFFFF;
} */

header.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  background-color: #e6e9ea;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,500&display=swap');
