[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 49 !important;
  margin-bottom: 59px !important;
}

[data-rsbs-header]:before {
  /* background-color: #033859 !important;
  top: calc(5px + env(safe-area-inset-top)) !important;
  left: calc(50% - 12.5px) !important;
  height: 25px !important;
  width: 25px !important;
  transform: skew(0deg, 0deg) !important;
  border-radius: 0px !important;
  clip-path: polygon(70% 30%, 90% 30%, 50% 65%, 10% 30%, 32% 30%, 50% 45%); */
  display: none !important;
}

[data-rsbs-header] {
  box-shadow: none !important;
}

.filterButton {
  display: flex;
  border: none;
  border-radius: 8px;
  color: #033858;
  background-color: #d9e1e6;
  font-size: 0.9rem;
  padding-block: 0.3rem;
  padding-inline: 1rem;
  white-space: nowrap;
}

.radioBottomContent{
  margin: 0 1rem 1.2rem 1.5rem;

}

.bottomSheet {
  background-color: #E6F1F4;
}

.bottomSheet div:nth-child(2) {
  background-color: #E6F1F4;
}

.radioTitle {
  color: #033858;
  font-weight: 600;
}

.radioOptions label span {
  color: #033858;
  font-weight: 500;
}

.singleOption {
  display: flex;
  align-items: center;
  padding-block: 0.5rem;
  box-shadow: 0 1px 0 rgba(46, 59, 66, calc(1 * 0.125));
}

.radioOptions label span:focus {
  font-weight: 600;
}

.radioOptions input[type='radio'] {
  /* appearance: none; */
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 0.9rem;
  height: 0.9rem;
  border: 0.2em solid #c2c2c2;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.radioOptions input[type='radio']:focus {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 0.95rem;
  height: 0.95rem;
  border: 0.25em solid #033858;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.radioOptions input[type='radio']:focus + span {
  font-weight: 600;
}

@media screen and (max-width: 412px) {
  .filterTitle{
    transform: translateY(1px);
  }
}
