.arrowBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ccd4d9;
    color: var(--mainColor);
    padding-top: 0.5rem;
    border-bottom: 2px solid #033858 !important;
    overflow: hidden;
  }
  
  .arrowBoxInactive {
    color: #7491a1;
    border-bottom: 2px solid #c4ced4;
    padding-inline: 1rem;
    padding-top: 0.5rem;
  }
  
  .activeStep {
    background-color: #ccd4d9;
    color: var(--mainColor);
    cursor: pointer;
  }
  
  .inactiveStep {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  }
  
  .step {
    text-decoration: none;
    font-weight: bold;
    height: auto;
    font-size: 0.8rem;
    padding: 0.5rem 0;
  }
  