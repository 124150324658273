.container{
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 1rem;
}

.baseTable{
    border: 1px solid #C0CDD5;
    border-radius: 8px;
    padding: 2rem 1rem;
}

.tableTr{
    color: #033858;
}

.tableTr th{
    padding-block: 1.5rem;
    border-bottom: 1px solid #C0CDD5;
}

.tableTh{
    text-align: center;
    border-bottom: 1px solid #C0CDD5;
}

.tableContent{
    text-align: center;
}

.tableContent td{
    padding-block: 1rem;
}