.modalSize{
  width: 730px !important;
}

.modalProduct p {
  max-width: 330px;
}

.discontModal {
  max-width: 580px;
}

.detailTags{

}

.modalBodyItem {
  padding-inline: 3rem;
  padding-block: 1.25rem;
}

.modalBackdrop {
  background-color: #7a95a5;
}

.itemBody {
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
}

.buttons{
  width: 325px;
}

.productCardImage{
  max-height: 300px;
  max-width: 280px;
}

.comparisonTable {
  border: 1px solid #c0cdd5;
  border-radius: 8px;
  padding-block: 1rem;
  margin-inline: .1rem;
}

.itemInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.itemName {
  text-align: start;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.1;
  padding-block: 1rem;
}

.addButtonProductPage {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #c5dadf;
  border-radius: 16px;
  width: 240px;
  margin-bottom: 1rem;
  height: 38px;
  margin-block: 1rem;
  align-items: center;
}

.marketValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c0cdd5;
  padding-inline: 1rem;
}

.marketValue:first-of-type span{
  font-weight: 600;
  padding-block: 0.3rem;
}

.marketValue:last-of-type span{
  padding-top: 0.5rem;
  padding-bottom: 0rem;
}

.marketValue:last-child {
  border-bottom: none;
}

.marketValue span {
  font-size: 1.1rem;
  padding-block: 0.5rem;
}

.modalAlert {
  max-width: 270px;
}

.exitButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: x-large;
}

.btnColored button {
  background-color: #c0dce2;
  color: #033859 !important;
}

.productListModal {
  position: absolute;
  color: #c2c2c2;
  font-size: 2.5rem;
  z-index: 2;
  left: 70px;
}

.productListModal:hover {
  color: var(--mainColor);
  cursor: pointer;
}

/* .modalRoot{
  --bs-modal-width: 300px !important;
} */

/* @media screen and (min-width: 992px) {
  .modalRoot > *:first-child{
    --bs-modal-width: 680px !important;
  }
} */
