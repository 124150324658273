.textStyle{
    font-weight: 500;
    border-bottom: 1px solid #C0CDD5;
    padding: 0.1rem;
}

.button{
    border-radius: 8px;
    background-color: #F2F5F6;
    font-weight: 500;
    color: #033858;
    border: 1px solid transparent;
}

.buttonSelected{
    border: 1px solid #033858;
}

.inputBox{
    border-radius: 4px;
    border: 1px solid #819CAC;
    width: 100%;
    min-height: 80px;
}

.sendButton{
    color: #ffff;
    font-weight: 500;
    background-color: #53BF9D;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1rem;
    text-align: center;
    border: none;
    width: 100%;
    margin-bottom: 5rem;
}
