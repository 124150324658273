.container{
  padding-bottom: 150px;
}

.logoStyle{
  border-bottom: 1px solid #033858;
}

.logoStyle img{
  height: 28px;
}

.faturaButton{
  position: fixed;
  bottom: 54px;
  width: 100%;
}

.faturaButton button{
  border: none;
  background-color: #033858;
  color: #f0f0f0;
  border-radius: 16px 16px 0 0;
  width: 100%;
  padding-block: 1rem 1.5rem; 
}

.billTotal{
  padding: 0.5rem;
  border: 1px solid #0D0D0D;
  border-radius: 4px;
}

.billTables{
  border: 1px solid #C2C2C2;
  border-radius: 4px;
}

.tableStyle{
  table-layout: fixed;
  width: 100%;
}

/* tbody tr:last-of-type{
  border-bottom: 1px solid #ffff;
} */

tr td{
  vertical-align: middle;
}

.productDescription{
  display: flex;
  flex-direction: column;
}

.descriptionStyle{
  font-weight: 500;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.notDelivered{
  display: flex; 
  justify-content: center; 
  font-weight: 500;
  font-size: 1.1rem;
  padding-block: 0.5rem;
  background-color: #F3F3F3;
}