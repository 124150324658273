.image{
  width: 80px;
  margin-bottom: 1rem;
}

.deleteButton:hover{
  background-color: #AB163F;
}

@media screen and (min-width: 992px) {
  .modalContainer > *:first-child{
    --bs-modal-width: 350px !important;
  }
}