.modalDialog {
  background-color: transparent !important;
}

.modalContent{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.modalHeader{
  background-color: var(--mainColor);
  border: none !important;
}

.scanner {
  position: relative;
  width: 100%;
  height: 100%;
}

 /* Overlay com transparência e a área central transparente */
 .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Truque com box-shadow para criar o furo transparente no meio */
.overlay::before {
  position: absolute;
  top: 30%;
  left: 10%;
  right: 10%;
  bottom: 30%;
  content: '';
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.45);
}

/* O conteúdo central pode ser colocado dentro desta div */
.content {
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  bottom: 20%;
  background: transparent; /* Opcional: Cor/arquivo/ou padrão de fundo */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  overflow: auto; /* Adiciona scroll se o conteúdo for muit grande */
}

.closeButton{
  position: absolute; /* Posicionamento absoluto */
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%); /* Ajusta o ponto de ancoragem para o centro do botão */
  width: 40px; /* Largura do botão */
  height: 40px; /* Altura do botão */
  line-height: 40px; /* Alinhamento vertical do texto no centro */
  text-align: center; /* Alinhamento horizontal do texto no centro */
  font-size: 20px; /* Tamanho da fonte do texto no botão */
  cursor: pointer; /* Muda o cursor para indicar que é clicável */
  background-color: var(--mainColor); /* Cor de fundo azul */
  color: white; /* Texto branco */
  border: none; /* Sem borda */
  border-radius: 50%; /* Bordas completamente arredondadas para formar um círculo */
}
