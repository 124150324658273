/* AOFAQ */

@import url('https://fontawesome.com/icons/angle-down?s=solid&f=classic');

.faqTitle {
  color: #033858;
  background-color: #e6e9ea;
  border-bottom: 1px solid #acafb0;
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
}

.faqContentTitle {
  font-size: 1.1rem;
  font-weight: 500;
}

.faqContentText {
  font-size: 1rem;
  font-weight: 400;
}
