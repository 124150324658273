.authenticationButton {
  background-color: #033858;
  color: #f0f0f0;
  border-radius: 8px;
  border: none;
  padding-block: 0.5rem;
  padding-inline: 2rem;
}

.authenticationButton {
  background-color: #033858;
  color: #f0f0f0;
  border-radius: 8px;
  border: none;
  padding-block: 0.5rem;
}

.authenticatorParagraph {
  font-weight: 500;
  font-size: 1.1rem;
}

/* STEP 3 */

.authenticationAlert {
  color: #033858;
  background-color: #ccd4d9;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-inline: 0.5rem;
  line-height: 100%;
}

.authenticationAlert span {
  font-size: 0.9rem;
}

.qrCodeAuthentication {
  background-color: #04738c;
  color: #f0f0f0;
  padding-block: 0.5rem;
  border-radius: 4px;
  border: none;
}

.authenticationCheckIcon {
  filter: brightness(0) saturate(100%) invert(29%) sepia(42%) saturate(3233%) hue-rotate(167deg)
    brightness(89%) contrast(97%);
}

.authenticationCheckButton {
  background-color: #04738c;
  border-radius: 8px;
  padding-inline: 1rem;
  padding-block: 0.2rem;
}

.inputBar {
  margin-bottom: 2.5rem;
}

.inputBar span {
  font-weight: 500;
}

.inputBar input {
  padding-block: 0.5rem;
  outline: none;
  padding-left: 1rem;
  max-width: 50%;
  background-color: #f0f0f0;
  font-weight: 400;
  outline-width: 0;
  border-radius: 8px;
}

.finalizeAuthenticationInput {
  border: 1px solid #b7b7b7;
  border-radius: 4px;
  color: #7e7e7e;
  font-weight: 500;
  font-size: 1.2rem;
}

::placeholder {
  color: #b7b7b7;
  opacity: 1;
}

.authenticationConfirmation span {
  font-weight: 500;
  font-size: 2rem;
  margin-block: 1.5rem;
}

.authenticationConfirmationButton {
  color: #f0f0f0;
  background-color: #04738c;
  border-radius: 8px;
  font-weight: 500;
  border: none;
  padding-inline: 2rem;
  padding-block: 0.5rem;
  margin-top: 1rem;
}

/* STEP 4 */

.stepAlertIcon {
  width: 60px;
}

/* AODISABLE */

.dataLabel {
  width: 24rem;
  padding: 0.4rem;
  border-radius: 8px;
  border: 1px solid #868686;
}

.modal-content {
  background-color: #f0f0f0;
  padding: 0rem;
  border-radius: 16px;
}

.modalBodyDisabling {
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 1rem;
}

.modalContentDisabling {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.authenticationDisable {
  border: 1px solid var(--borderColor);
  outline: none;
  border-radius: 4px;
  padding-inline: 1rem;
  padding-block: 0.5rem;
}

.cancelationCheckIcon {
  color: var(--dangerColor);
  font-size: 3rem;
}

.disableIcon {
  filter: brightness(0) saturate(100%) invert(29%) sepia(78%) saturate(7108%) hue-rotate(334deg)
    brightness(87%) contrast(92%) !important;
}
