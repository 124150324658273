.container{
    display: flex;
    justify-content: space-between;
    background-color: #F2F5F6;
    padding: 0.6rem 0.5rem;
}

.city{
    color: #033858;
    text-decoration: underline;
}

.message img{
    height: 12px;
    transform: translateY(-2px);
    margin-right: 2px;
}