.authenticationProgress {
  display: block;
  clear: both;
  margin-top: 2rem;
  width: auto;
  font-family: sans-serif;
  overflow: hidden;
}

.checkoutProgress div {
  box-sizing: border-box;
}

.sticky{
  position: sticky;
  top: 44px;
  z-index: 5;
}

.fixed {
  position: fixed;
  width: 100%;
  top: 45px;
  z-index: 6;
}

.arrowBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ccd4d9;
  color: var(--mainColor);
  padding-top: 0.5rem;
  border: 1px solid #c4ced4;
}

.arrowBoxDesktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ccd4d9;
  color: var(--mainColor);
  padding-inline: 6rem;
  padding-block: 0.2rem;
  border: 1px solid #c4ced4;
  z-index: 1;
  margin-right: -1.35rem;
  border-radius: 8px;
  margin-inline: 0.8rem;
  font-size: 1.2rem;
  border: 1px solid #7a95a5;
}

.checkoutProgress {
  display: flex;
  justify-content: center;
  clear: both;
  margin-bottom: 1.5rem;
  font-family: sans-serif;
  overflow: auto;
}

.step {
  padding: 5px 5px 5px 10px;
  color: #ccd4d9;
  text-decoration: none;
  cursor: default;
  font-weight: bold;
  float: left;
  height: auto;
  font-size: 0.8rem;
}

.stepDesktop {
  padding: 5px 5px 5px 10px;
  color: #ccd4d9;
  text-decoration: none;
  cursor: default;
  font-weight: bold;
  float: left;
  height: auto;
  font-size: 1.2rem;
}

.arrowBoxInactive {
  color: #7491a1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #c4ced4;
  padding-inline: 1rem;
  padding-top: 0.5rem;
  z-index: 0;
}

.inactiveStep {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.inactive {
  background: #fff;
}

.activeStep {
  background-color: #ccd4d9;
  color: var(--mainColor);
  cursor: pointer;
}

.tirangleActive {
  width: 0;
  float: left;
  border-top: 20px solid transparent;
  border-left: 15px solid #ccd4d9;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2Active {
  width: 0;
  float: left;
  border-top: 20px solid #ccd4d9;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #ccd4d9;
  margin-right: -1px;
}

.triangleInactive {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid rgb(154, 19, 19);
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2Inactive {
  float: left;
  width: 0;
  border-top: 20px solid #fff;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #fff;
  margin-right: -1px;
}

.inactiveDesktop {
  color: #ced6db;
  background-color: #e7e7e7;
  border: none;
}
