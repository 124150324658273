:root {
  --mainColor: #033858;
  --secondColor: #53bf9d;
  --successColor: #5ccb58;
  --lightMain: #cddde1;
  --anotherLight: #d8dee1;
  --dangerColor: #d61c4e;
  --alertColor: #e8ae6d;
  --shadow: 5px 1px 10px #7b7b7b72;
  --borderColor: #C0CDD5;
  --springBottom: #e6e9ea;
  background-color: transparent;
  font-size: 0.9rem;
}

:root input {
  outline: none;
}

:root .container {
  --bs-gutter-x: 2rem;
}

.modal-dialog{
  /* margin: 0px; */
}

.grid-container {
  display: grid;
}

.loading-container{
  min-height: 100vh;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

#root.barcode-scanner-active {
  visibility: hidden;
  background-color: transparent;
  --background: transparent;
  --ion-background-color: transparent;
}

#root-modals{
  background-color: transparent !important;
}

a {
  text-decoration: none !important;
}

ol > li::marker {
  font-weight: bold;
}

.carousel-control-next-icon{
  background-image: url(../public/media/Carousel/Hero-Right.svg) !important;
}

.carousel-control-prev-icon{
  background-image: url(../public/media/Carousel/Hero-Left.svg) !important;
}

.bgGradient {
  background: rgb(83, 191, 157);
  background: linear-gradient(
    135deg,
    rgba(83, 191, 157, 1) 0%,
    rgba(4, 115, 140, 1) 75%,
    rgba(3, 56, 89, 1) 100%
  ) !important;
  height: 100%;
}

/* BADGES */

.bubbleStatus1 {
  background-color: #3f8acb;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  text-align: center;
}

.bubbleStatus2 {
  background-color: #e8ae6d;
}

.bubbleStatus3 {
  background-color: #f15555;
}

.bubbleStatus4 {
  background-color: #5ccb58;
}

.bottom-sheet {
  background-color: var(--springBottom);
  --rsbs-bg: #e6e9ea !important;
}

.background-color-f0 {
  background-color: #f0f0f0;
}

.background-color-emphasis {
  background-color: #c0cdd5;
}

.background-grey {
  color: #868686 !important;
  background-color: #DBDBDB;
}

.background-grey:hover {
  background-color: #C2C2C2;
}

.btn-main {
  background-color: #033858;
}

.btn-second {
  background-color: #53bf9d;
}

.btn-second:hover{
  background-color: #41947A;
}

.btn-third {
  background-color: #04738c;
}

.btn-success {
  background-color: #5ccb58;
}

.btn-light {
  background-color: #cddde1;
  color: #868686 !important;
}
.btn-light:hover {
  background-color: #C2C2C2;
  color: #868686;
}

.btn-danger {
  background-color: #d61c4e;
}
.btn-danger:hover {
  background-color: #AB163F;
}

.btn-alert {
  background-color: #e8ae6d;
}

.cheaper {
  background-color: #40b297;
}

.expensive {
  background-color: #e0557a;
}

.cheaper-color {
  color: #40b297;
}

.expensive-color {
  color: #e0557a;
}

.alert-color {
  color: #e8ae6d;
}

.danger-color {
  color: #D61C4E;
}

.black-color {
  color: #0d0d0d !important;
}

.main-color {
  color: #033858 !important;
}

.faded-color{
  color: #4A4A4A;
}

.main-filter {
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
    brightness(100%) contrast(98%) !important;
}

.white-filter {
  filter: brightness(0) saturate(100%) invert(87%) sepia(94%) saturate(0%) hue-rotate(353deg) brightness(105%) contrast(105%)
}

.gray-filter{
  filter: invert(70%) sepia(0%) saturate(131%) hue-rotate(150deg) brightness(77%) contrast(78%);
}

.faded-filter {
  filter: invert(81%) sepia(27%) saturate(96%) hue-rotate(159deg) brightness(140%) contrast(55%);
}

.black-filter{
  filter: invert(0%) sepia(89%) saturate(14%) hue-rotate(100deg) brightness(20%) contrast(100%);
}

.canceled-filter{
  filter: invert(53%) sepia(91%) saturate(3532%) hue-rotate(331deg) brightness(99%) contrast(91%);
}

.second-color {
  color: #53bf9d;
}

.third-color {
  color: #04738c;
}

.danger-color {
  color: #d61c4e;
}

.canceled-color {
  color: #FAA9A3;
}

.faded-color {
  color: #868686 !important;
}

.w-250px {
  width: 250px;
}

.w-350px {
  width: 350px;
}

.w-450px {
  width: 450px;
}

.w-550px {
  width: 550px;
}

.w-650px {
  width: 650px;
}

.chevron-size {
  font-size: 1.8rem;
}

.pb-2rem{
  padding-block: 2rem;
}

.padding-bottom-mobile{
  padding-bottom: 80px;
}

.padding-bottom-cart-mobile{
  padding-bottom: 120px;
}

.button {
  border: none;
  border-radius: 8px;
  color: #f0f0f0;
  font-weight: 500;
  padding-block: 0.3rem;
  padding-inline: 1.2rem;
  cursor: pointer;
}

.fat-button {
  border: none;
  border-radius: 32px;
  color: #f0f0f0;
  font-weight: 500;
  padding-block: 0.8rem;
  padding-inline: 1.6rem;
}

.button-color {
  color: #f0f0f0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-undertext:hover {
  text-decoration: underline;
}

.text-center{
  text-align: center;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-16 {
  border-radius: 16px;
}

.top-line {
  border-top: 1px solid var(--borderColor);
}

.bottom-line {
  border-bottom: 1px solid var(--borderColor);
}

.border-outline {
  border: 1px solid var(--borderColor);
}

.width80{
  width: 80%;
}

.width1rem {
  width: 1rem;
}

.form-control::placeholder{
  color: #C0CDD5;
}

.form-control:focus {
  color: #0D0D0D;
  background-color: #fff;
  border-color: #0D0D0D;
  outline: 0;
  box-shadow: none;
}

.form-check-input:checked{
  border-color: #033858;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

/* TRANSFORM */

.transform1px{
  transform: translateY(1px);
}

.transform2px{
  transform: translateY(2px);
}

.transform-1px{
  transform: translateY(-1px);
}

.transform-2px{
  transform: translateY(-2px);
}

.transform-4px{
  transform: translateY(-4px)
}

.checklist-icons{
  transform: translateY(6px);
}

/* FONT */

.font300 {
  font-weight: 300;
}

.font500 {
  font-weight: 500;
}

.font600 {
  font-weight: 600;
}

.font07rem {
  font-size: 0.7rem;
}

.font08rem {
  font-size: 0.8rem;
}

.font09rem {
  font-size: 0.9rem;
}

.font10rem {
  font-size: 1rem;
}

.font11rem {
  font-size: 1.1rem;
}

.font12rem {
  font-size: 1.2rem;
}

.font14rem {
  font-size: 1.4rem;
}

.font15rem {
  font-size: 1.5rem;
}

.font17rem {
  font-size: 1.7rem;
}

.font20rem {
  font-size: 2rem;
}

.font30rem {
  font-size: 3rem;
}

.text-underline{
  text-decoration: underline;
}

.mb-100px {
  margin-bottom: 100px;
}

.accordion {
  --bs-accordion-bg: none;
  --bs-accordion-border-color: none;
}

.accordion-button:not(.collapsed) {
  color: #0d0d0d;
  background-color: #f0f0f0 !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}

.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}

/* SCROLL */

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar:hover {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #819cacb0;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #819cac;
  border-radius: 8px;
}

/* SLIDER */

.progress{
  border: 1px solid #819CAC;
  --bs-progress-bg: transparent !important;
}

.rs-slider-bar{
  background-color: #d9d9d9 !important;
}

.rs-slider-progress-bar{
  background-color: #033858 !important;
}

.rs-slider-handle::before{
  border: 2px solid #033858 !important;
  background-color: #033858 !important;
}

.rs-slider-handle:hover::before, .rs-slider-handle:focus::before{
  box-shadow: 0 0 0 6px #03385840 !important;
}

.modal-content{
  border-radius: 16px;
}

.customModal {
  position: absolute !important;
  right: 0 !important;
}

/* PRODUCT CAROUSEL */

.carousel .thumbs-wrapper {
  margin: 0px !important;
}

.carousel .control-dots{
  bottom: -15px !important;
}

.carousel .control-dots li{
  opacity: 1 !important;
  background: #C0CDD5 !important;
  box-shadow: none !important;
  width: 9px !important;
  height: 9px !important;
}

.carousel .control-dots .dot.selected{
  background: transparent !important;
  border: 2px solid #033858 !important;
}

.valid{
  color: #019875;
}

.chevronDown{
  transform: rotateZ(180deg);
  transition: 0.5s;
}

.chevronUp{
  transition: 0.5s;
}

.col20{
  width:20%; float:left
}

.link{
  text-decoration: underline;
  color: #033858;
  font-weight: 500;
  cursor: pointer;
}

@media (min-width: 576px) {
  .container-sm {
    max-width: 800px;
  }
}

@media screen and (min-width: 500px) {
  :root {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  :root {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 412px) {
  :root {
    font-size: 0.8rem;
  }
}

@keyframes react-spinners-Cliploader-clip {
  50%{
    transform: rotate(180deg) scale(1) !important;
  }
}
