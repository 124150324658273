/* ORDER */

.order{
  border: 1px solid #C0CDD5;
  border-radius: 8px;
  padding: 2rem 1rem;
}

.ordersFormSelector label {
  color: var(--mainColor);
}

.emptyPage {
  border: 1px solid #C0CDD5;
  text-align: center;
  border-radius: 16px;
  padding: 2rem;
  width: 100%
}

.startBuyingButton{
  border: 1px solid #033858;
  color: #033858;
  font-weight: 500;
  border-radius: 100px;
  padding: 0.6rem 1.5rem;
  cursor: pointer;
}

.startBuyingButton:hover{
  color: #FFFF;
  background-color: #033858;
}

.ordersFormSelector select {
  background-color: #e6e9ea;
  border-radius: 8px;
  border: none;
  padding-block: 0.2rem;
  padding-inline: 1rem;
}

.linkLines {
  color: var(--mainColor);
  cursor: pointer;
}

.groupedList{
  border: 1px solid #C0CDD5;
  border-radius: 8px;
}

.ordersFormSelector select:focus-visible {
  outline: none;
}

.orderForm {
  background-color: none !important;
  border-radius: 0px !important;
  padding: 0rem !important;
}

.orderBubble {
  border: 1px solid #c4ced4;
  border-radius: 8px;
  font-weight: 500;
}

.bubbleOpen {
  background-color: #e6e9ea;
}

.orderBubbleStatus {
  border-radius: 8px;
  padding-block: 0.1rem;
  padding-inline: 0.5rem;
  color: #f0f0f0;
  font-weight: 500;
}

.bubbleStatusentregue {
  background-color: #5ccb58;
}

.bubbleStatusem_rota {
  background-color: #e8ae6d;
}

.bubbleStatuscancelado {
  background-color: #f15555;
}

.bubbleStatusaguardando {
  background-color: #3f8acb;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  text-align: center;
}

.orderBubbleSpans span {
  margin-bottom: 0.6rem;
}

.orderIcons {
  margin-block: 1rem;
}

.orderIconsButtons:hover {
  background-color: #F2F5F6;
  font-weight: 500;
}

.orderIconsButtons {
  border: 1px solid #033859 !important;
  background-color: #fff;
  color: #033858;
  font-weight: 400;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0.1;
  white-space: nowrap;
  padding-block: 1.8rem;
  padding-inline: 2rem;
  min-width: 150px;
  cursor: pointer;
}

.orderIconsButtons svg {
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
    brightness(100%) contrast(98%);
}

.disabledButtons{
  border: 1px solid #C0CDD5 !important;
  background-color: #fff;
  color: #C0CDD5;
  font-weight: 400;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0.1;
  white-space: nowrap;
  padding-block: 1.8rem;
  padding-inline: 1.4rem;
}

.disabledButtons img {
  filter: invert(81%) sepia(27%) saturate(96%) hue-rotate(159deg) brightness(140%) contrast(55%);
}

.orderPrintButton{
  border: none;
  border-radius: 8px;
  color: #f0f0f0;
  background-color: #033858;
  font-weight: 500;
  padding-block: 1rem;
  padding-inline: 2rem;
  cursor: pointer;
}

/* RASTREIO */

/* FATURA */

.faturaButton {
  background-color: #033858;
  color: #f0f0f0;
  border: none;
  padding-inline: 2rem;
  padding-block: 0.5rem;
  margin-block: 1rem;
  border-radius: 8px;
}

.faturaLine {
  border-bottom: 1px solid #d9d9d9;
}

.faturaHighlight {
  color: var(--mainColor);
  font-weight: 600;
  margin-top: 0.8rem;
}

/* AOData */

.dataLabel {
  border: 1px solid #c0cdd5;
  border-radius: 8px;
  margin-right: 1rem;
  font-size: 1.1rem;
  padding: 0.25rem 0rem 0.2rem 1rem;
}

.iconSize{
  width: 28px;
  height: 28px;
}

.dataBar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  padding: 1rem;
  background-color: #e6ecef;
}

.formTitle {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--mainColor);
  display: flex;
  width: 100%;
  transform: translateY(2px);
}

.errorMessage {
  color: #f15555;
  font-size: 0.8rem;
}


/* AOPassword */

.inputStyle {
  width: 24rem;
  padding: 0.4rem;
  border-radius: 8px;
  border: 1px solid #868686;
}

.modalBodySuccess {
  background-color: #f0f0f0;
  border-radius: 16px;
}

.modalContentSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* AOCards */

.noButton {
  border: none;
  border-radius: 8px;
  color: #868686;
  background-color: #dbdbdb;
  font-weight: 500;
  padding-block: 0.3rem;
  padding-inline: 1.2rem;
}

/* AOLists */

.bubble {
  height: 140px;
  display: flex;
  align-items: stretch;
}

.bubbleNumber {
  background-color: #c0dce2;
  border: 1px solid #c0cdd5;
  border-radius: 16px 0 0 16px;
  padding-block: 2rem;
  padding-inline: 1rem;
  color: var(--mainColor);
  font-size: 2rem;
  font-weight: 600;
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bubbleText {
  border: 1px solid #c0cdd5;
  border-radius: 0 16px 16px 0;
  padding: 2rem;
  color: var(--mainColor);
  width: 86%;
  display: flex;
  align-items: center;
}

.listIcon {
  vertical-align: revert;
  height: 1.5rem;
  transform: translateY(3px);
}

.addSubtract {
  border: none;
  color: #033859;
  text-decoration: none !important;
  font-size: 1.4rem;
  font-weight: 500;
}

.quantity {
  background-color: #e6e9ea;
  border-radius: 8px;
  padding-inline: 1rem;
  padding-block: 0.5rem;
}

.boxBubble {
  font-size: 0.85rem;
  font-weight: 500;
  margin-top: 0.6rem;
  color: #033859;
  background-color: #c5dadf;
  border-radius: 8px;
  padding-inline: 0.2rem;
  padding-block: 0.3rem;
}

.editButton {
  width: 20px;
  cursor: pointer;
}

.cartIcon{
  cursor: pointer;
  transform: translateY(-1px);
}

.editButton{
  width: 16px;
  margin-left: 0.5rem;
  transform: translateY(-2px);
}

.trashCanList {
  color: var(--mainColor);
  background-color: #F2F5F6;
  transform: translateY(5px);
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-right: 0.4rem;
  padding: 4px 8px;
}

.trashCanList:hover {
  background-color: #C9CBCC;
  cursor: pointer;
}

.listsGrid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem 0.5rem;
  margin-bottom: 2rem;
  border: 1px solid #C0CDD5;
  border-radius: 8px;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  margin-top: 2rem;
}

.listDetailTrash{
  height: 16px;
  filter: invert(0%) sepia(89%) saturate(14%) hue-rotate(100deg) brightness(20%) contrast(100%);
}

.listItems{
  display: flex;
  flex-direction: column;
  border: 1px solid #C0CDD5;
  border-radius: 8px;
  padding: 1.5rem 1rem;
  margin-top: 2rem;
}

.listControls{
  display: flex;
  justify-content: space-between;
}

.listControls span{
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
}

.backToListsButton{
  background-color: #ffff;
  color: #033858;
  border-radius: 100px;
  border: 1px solid #033858;
  padding: 0.5rem 1.5rem;
  font-weight: 500;
}

.backToListsButton img{
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
  brightness(100%) contrast(98%);
  transform: translateY(-1px);
  margin-right: 4px;
}

.backToListsButton:hover img{
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.backToListsButton:hover{
  background-color: #033858;
  color: #f0f0f0;
  cursor: pointer;
}

/* AOCupons */

.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F2F5F6;
  font-size: 0.9rem;
  border-radius: 8px;
  padding: 1rem;
  color: #033858;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.alertIcon {
  font-size: 2.5rem;
  color: var(--mainColor) !important;
  transform: translateY(-1px);
  margin-right: 0.5rem;
}

.heartIcon{
  width: 54px;
  margin-right: 1rem;
}

.missionCards{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem 1rem;
  margin-bottom: 2rem;
}

/* ANIMATED CHEVRON  */

/* AOContact */

.bubbles {
  border: 1px solid #c0cdd5;
  border-radius: 16px;
  padding: 1.5rem;
  min-height: 225px;
}

.faqButton {
  background-color: #d9e1e6;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  color: #033858;
  padding-inline: 0.5rem;
  padding-block: 0.4rem;
}

/* AODonations */

/* AO ORDERS TRACKING */

.deliveryPrediction {
  color: var(--mainColor);
  background-color: #e6e9ea;
  font-weight: 500;
}

.rastreioStepOne {
  position: relative;
  margin-bottom: 5rem;
}

.rastreioStep {
  position: relative;
  margin-bottom: 4.5rem;
}

.icon {
  font-size: 2rem;
  color: var(--mainColor);
  margin-inline: 3rem;
  z-index: 2;
}

.iconFaded {
  font-size: 2rem;
  color: #c2c2c2;
  margin-inline: 3rem;
  z-index: 2;
}

.canceled {
  background-color: #f15555;
  border-radius: 50%;
  padding: 2rem;
  z-index: 1;
  position: absolute;
  margin: 2rem;
}

.waiting {
  background-color: #c0dce2;
  border-radius: 50%;
  padding: 2rem;
  z-index: 1;
  position: absolute;
  margin: 2rem;
}

.approved {
  background-color: #c0cdd5;
  border-radius: 50%;
  padding: 2rem;
  z-index: 1;
  position: absolute;
  margin: 2rem;
}

.faded {
  background-color: #e7e7e7;
  border-radius: 50%;
  padding: 2rem;
  z-index: 1;
  position: absolute;
  margin: 2rem;
}

.rastreioLineFaded {
  position: absolute;
  border-bottom: 2px dotted #868686;
  transform: rotate(0.5turn);
  width: 150px;
  top: 16px;
  left: 105px;
  z-index: 0;
}

.rastreioLine {
  position: absolute;
  border-bottom: 2px dotted #033858;
  transform: rotate(0.5turn);
  width: 150px;
  top: 16px;
  left: 105px;
  z-index: 0;
}

.outlineButton{
  border: 2px solid #033858;
  color: #033858;
}

.outlineButton:hover{
  background-color: #033858;
  color: #f0f0f0;
}

.list{
  color: #033858;
  text-align: start;
}

.list li{
  min-height: 31px;
}

/* SUGGESTIONS */

.suggestionHeader{
  display: flex;
  align-items: center;
  background-color: #F2F5F6;
  color: #033858;
  border-radius: 8px;
  padding: 1rem 3rem;
}

.suggestionHeader img{
  padding-right: 1.5rem;
  height: 48px;
}

.suggestedLists{
  border: 1px solid #C0CDD5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  margin-top: 2rem;
}

.listCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
}

.listCard span{
  margin-top: 0.6rem;
  font-weight: 500;
}

.listCard > img{
  height: 96px;
  width: 96px;
}

.listButton{
  color: #033858;
  background-color: #F2F5F6;
  border-radius: 8px;
  padding: 0.2rem 1rem;
  font-weight: 500;
  margin-top: 0.6rem;
}

.listButton:hover {
  background-color: #C9CBCC;
  cursor: pointer;
}

.listButton img{
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
  brightness(100%) contrast(98%);
  margin-left: 0.5rem;
}

/* SUGESTED */

.suggestedItems{
  display: flex;
  flex-direction: column;
  border: 1px solid #C0CDD5;
  border-radius: 8px;
  padding: 1.5rem 1rem;
  margin-top: 2rem;
}

.suggestedItems > span{
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
}

.productTitle{
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: 300px;
}

.barCode {
  font-size: 0.75rem;
  color: #4A4A4A;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  width: 200px;
}

.brandName{
  font-size: 0.75rem;
  color: #4A4A4A;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  margin-left: 1rem;
}

.fullPrice{
  font-size: 0.9rem;
  text-decoration: line-through;
  color: #4A4A4A;
}

.trashCan {
  color: #7994a4;
  display: flex;
  align-items: center;
}

.trashCan :hover {
  color: var(--mainColor);
  cursor: pointer;
}

/* RECEIPT STYLE */

.receiptContainer{
  border: 1px solid #C0CDD5;
  border-radius: 8px;
  padding: 1.5rem 1rem;
  margin-top: 2rem;
}

.receiptGroup{
  border-radius: 8px;
  background-color: #F2F5F6;
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.receiptButton{
  background-color: #033858;
  border-radius: 8px;
  color: #fff;
  padding: 0.5rem 2rem;
  width: fit-content;
  margin-top: 1.5rem;
  cursor: pointer;
}

.printerIcon{
  margin-left: 0.8rem;
  transform: scale(1.6) translateY(-1px);
}

/* INVOICE STYLE */

.invoiceContainer{
  border: 1px solid #C0CDD5;
  border-radius: 8px;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 2rem;
}

.invoiceLogo{
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #C0CDD5;
  padding-bottom: 0.5rem;
}

.invoiceLogo img{
  transform: scale(0.6);
}

.tablesContainer{
  border: 1px solid #0D0D0D;
  border-radius: 8px;
}

.tableName{
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 500;
  padding-block: 0.5rem;
  border-bottom: 1px solid #4A4A4A;
}

.tdStyles{
  border-bottom: 1px solid #4a4a4a;
  padding-block: 0.5rem;
  width: 100%;
  font-weight: 500;
}

.tdStyles td{
  text-align: center;
}

.tableContent{
  text-align: center;
}

.borderTop{
  border-top: 1px solid #4a4a4a;
}

.itemDescription{
  padding-left: 3rem;
  width: 530px;
}

.itemDescription div span{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.invoiceTotal{
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  padding-top: 1rem;
  border-top: 1px solid #0D0D0D;
}

.invoiceDownloadButton{
  background-color: #033858;
  font-weight: 500;
  padding: 1rem 4rem;
  color: #fff;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
}

.invoiceDownloadIcon{
  height: 22px;
  margin-left: 0.5rem;
}
