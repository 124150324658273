.addNew {
  border: none;
  border-radius: 8px;
  background-color: #c0dce2;
}

.addNew:hover{
  box-shadow: 8px 8px 14px #0d0d0d1a;
}

.addToCart {
  font-weight: 500;
  display: flex;
  padding-block: 0.3rem;
  padding-inline: 1rem;
  color: #033859;
  border: none;
  column-gap: 0.25rem;
}

.addToCart p{
  display: flex;
  align-items: flex-end;
  margin: 0;
  line-height: 1;
}

.itemCardCounter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  z-index: 2;
}

.remove{
  border: none;
  font-weight: 500;
  font-size: 1.1rem;
  width: 32px;
  background-color: transparent;
}

.barCounter{
  width: 2.5rem;
  background-color: transparent;
  text-align: center;
  border: none;
  font-weight: 500;
  font-size: 1.1rem !important;
  min-width: 80px;
}

.add{
  border: none;
  font-weight: 500;
  font-size: 1.1rem;
  width: 32px;
  height: 32px;
  background-color: transparent;
}
