.orderBubble {
  border: 1px solid #c4ced4;
  border-radius: 8px;
  font-weight: 500;
}

.orderBubbleDonation {
  border-radius: 8px;
  padding-block: 0.2rem;
  padding-inline: 1rem;
  color: #f0f0f0;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.bubbleStatusdoado {
  background-color: #5ccb58;
  max-width: 80px;
  color: #f0f0f0;
}

.bubbleStatusem_arrecadação {
  background-color: #3f8acb;
  color: #f0f0f0;
}

.bubbleStatuscancelado {
  background-color: #f15555;
  max-width: 90px;
  color: #f0f0f0;
}