.navBarLinks {
  text-decoration: 'none';
  position: relative;
  color: #033858;
  display: inline-flex;
  align-items: center;
  margin: 0 0.5rem;
  cursor: pointer;
}

.cartArea {
  display: contents;
}
.cartArea img{
  height: 22px;
}

button.NotificationCounter{
  display: flex;
  position: absolute;
  background-color: var(--secondColor);
  color: white;
  border: none;
  margin-top: 1px;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  font-feature-settings: 'pnum';
  font-variant: proportional-nums;
  transform: translate(10px, -10px);
  align-items: center;
  justify-content: center;
}

button.NotificationCounter:hover {
  background-color: var(--mainColor);
  cursor: pointer;
}

/* .NotificationCounter span{
  transform: translateY(1px);
} */
