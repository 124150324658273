.iconBubble{
    padding: 1.4rem;
    border-radius: 100px;
    max-width: 105px;
    max-height: 105px;
    display: flex;
    justify-content: center;
}

.canceledBubble{
    background-color: #FAA9A3;
}

.iconBubbleInnactive{
    background-color: #E7E7E7;
}

.iconBubbleCurrent{
    background-color: #C5DADF;
}

.iconBubblePassed{
    background-color: #D9E1E6;
}

.textFadded{
    color: #C2C2C2;
    text-align: center;
}