.container h2 {
  color: var(--mainColor);
}

.profileOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--mainColor);
  border-bottom: 1px solid #b5c2ca;
}

.smallIcons{
  min-width: 32px;
}

.profileCategory {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profileCategory p {
  margin: 0px;
}

.profileCategory span {
  color: #3e667f;
  font-size: 0.9rem;
}

.profileClasses h6 {
  color: var(--mainColor);
  font-size: 1.2rem;
}

.profileFormContainer {
  margin-bottom: 2rem;
}

.containerForm {
  background-color: #e6e9ea;
  border-radius: 16px;
  padding: 2rem;
}

.container form h4 {
  border-bottom: 1px solid #b5c2ca;
  color: var(--mainColor);
  margin-top: 1rem;
}

.profileFormButton {
  padding-block: 0.8rem;
  background-color: #cddde1;
}

.profileFormButton button {
  color: #f0f0f0;
  background-color: #04738c;
  border: none;
  border-radius: 8px;
  padding-block: 0.8rem;
  width: auto;
  padding-inline: 2rem;
  max-width: 40%;
}

.collapsibleClosed::after {
  display: block;
  transition: transform 300ms;
  border-style: solid;
  border-width: 0.13rem 0.13rem 0 0;
  content: '';
  display: inline-block;
  height: 8px;
  width: 8px;
  transform: rotate(45deg);
  vertical-align: top;
  margin-left: 1rem;
  position: absolute;
  right: 25px;
  margin-top: 8px;
}

.collapsibleOpen::after {
  display: block;
  transition: transform 300ms;
  border-style: solid;
  border-width: 0.13rem 0.13rem 0 0;
  content: '';
  display: inline-block;
  height: 8px;
  width: 8px;
  transform: rotateZ(135deg);
  vertical-align: top;
  margin-left: 1rem;
  position: absolute;
  right: 25px;
  margin-top: 8px;
}

.collapsibleText {
  color: #3e667f;
  font-size: 0.9rem;
}

.exit{
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: 500;
  color: var(--mainColor);
}
