.arrowBoxInactive {
    border-bottom: 2px solid #c4ced4;
    background-color: #fff;
    padding-inline: 1rem;
    padding-block: 0.6rem;
    z-index: 0;
  }

  .arrowBox {
    color: var(--mainColor);
    padding-block: 0.6rem;
    z-index: 1;
    font-size: 1rem;
    border-bottom: 2px solid #033858;
    background-color: #D9E1E6;
  }

  .step {
    padding: 5px 0 0 0;
    color: #ccd4d9;
    font-weight: bold;
  }

  .inactiveStep {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    cursor: initial;
  }

  .activeStep {
    color: var(--mainColor);
  }

  .hasLink {
    cursor: pointer;
  }
  
  @media screen and (max-width: 800px) {
    .step {
      font-size: 0.8rem;
    }
  }  

  @media screen and (max-width: 412px) {
    .step {
      padding: 5px 0 0 0;
    }
  }  