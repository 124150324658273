.search{
    position: sticky;
    top: 44px;
    z-index: 5;
  }
  
  .formField {
    padding-block: 1rem;
    background-color: #ffff;
  }
  
  .search input {
    background-color: #F2F5F6;
    border: none;
    border-radius: 4px;
    font-size: 1.2rem !important;
    height: 3rem;
    width: 100%;
    padding-left: 3rem;
    z-index: 2;
  }
  
  .searchInputs {
    display: flex;
    flex-direction: row-reverse;
  }
  
  .searchBar{
    font-size: 22px;
  }
  
  .searchIcon {
    position: absolute;
    left: 30px;
    color: #033858;
    font-size: 1.8rem;
    z-index: 3;
  }
  
  .searchIconFaded {
    position: absolute;
    left: 30px;
    filter: invert(80%) sepia(22%) saturate(65%) hue-rotate(75deg) brightness(90%) contrast(87%);
    font-size: 1.8rem;
    z-index: 3;
  }
  
  .XIcon {
    position: absolute;
    right: 35px;
    font-size: 1.8rem;
    z-index: 3;
  }