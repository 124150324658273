.startBuyingButton{
  border: 1px solid #033858;
  color: #033858;
  font-weight: 500;
  border-radius: 100px;
  padding: 0.6rem 1.5rem;
  cursor: pointer;
}

.startBuyingButton:hover{
  color: #FFFF;
  background-color: #033858;
}
