.authenticationButton {
  background-color: #033858;
  color: #f0f0f0;
  border-radius: 8px;
  border: none;
  padding-block: 0.5rem;
}

.finalButton {
  background-color: #53BF9D;
  color: #f0f0f0;
  border-radius: 8px;
  border: none;
  padding-block: 0.5rem;
}

.authenticatorParagraph {
  font-weight: 500;
  font-size: 1.1rem;
}

.authenticationAlert {
  color: #033858;
  background-color: #ccd4d9;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-inline: 0.5rem;
  line-height: 100%;
}

.authenticationAlert span {
  font-size: 0.9rem;
}

.qrCodeAuthentication {
  background-color: #F2F5F6;
  color: #033858;
  font-weight: 500;
  padding-block: 0.5rem;
  border-radius: 4px;
  padding-inline: 2rem;
  border: none;
}

.authenticationCheckIcon {
  color: #04738c;
  font-size: 3rem;
}

.authenticationCheckButton {
  background-color: #04738c;
  border-radius: 8px;
  padding-inline: 1rem;
  padding-block: 0.2rem;
}

.inputBar {
  margin-bottom: 1rem;
}

.inputBar span {
  font-weight: 500;
}

.inputBar input {
  padding-block: 0.5rem;
  outline: none;
  padding-left: 1rem;
  max-width: 60%;
  font-weight: 400;
  outline-width: 0;
}

.finalizeAuthenticationInput {
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  color: #7e7e7e;
  font-weight: 500;
  font-size: 1.2rem;
}

::placeholder {
  color: #b7b7b7;
  opacity: 1;
}

.authenticationConfirmation span {
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 1rem;
}

.authenticationConfirmationButton {
  color: #f0f0f0;
  background-color: #04738c;
  border-radius: 8px;
  font-weight: 500;
  border: none;
  padding-inline: 1.5rem;
  padding-block: 0.2rem;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

/* CANCELATION */

.authenticationDisable {
  border: 1px solid var(--borderColor);
  outline: none;
  border-radius: 4px;
  padding-inline: 1rem;
  padding-block: 0.5rem;
}

.cancelationCheckIcon {
  color: var(--dangerColor);
  font-size: 3rem;
}

.bottomSheet{
  animation-duration: 1s;
}

/* STEP 4 */

.stepAlertIcon {
  width: 140px;
}

/* CONCLUSION */

.disableButton{
  background-color: #F2F5F6;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  color: #033858;
  padding: 1rem;
  margin-top: 1.5rem;
}

.cancelStyle{
  text-decoration: underline;
  color: #033858;
  font-size: 0.9rem;
  margin-top: 0.2rem;
}