.footmenu {
  position: fixed;
  overflow: hidden;
  bottom: 0px;
  z-index: 6;
  width: 100%;
  background-color: #e6e9ea;
}

.footmenuInfo img {
  height: 18px;
}

.footmenuInfo p {
  margin-bottom: 0px;
  color: white;
  font-weight: 600;
}

.footmenuOptions {
  padding-block: 0.8rem;
  background-color: #e6e9ea;
  width: 100vw;
}

.footmenuMenu .spam {
  font-weight: 600;
  font-size: 0.9rem;
}

.footmenuIcon svg {
  height: 1.5rem;
  width: 1.5rem;
  color: var(--mainColor);
}

.footmenuIcon {
  font-size: 0.8rem;
}

.footmenuIcon span {
  font-weight: 500;
}
