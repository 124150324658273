.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.6rem 0.6rem;
  margin-bottom: 5rem;
}

.completeGrid {
  margin-bottom: 5rem;
}

.completeCardCategory {
  text-align: center;
}

.completeCardCategory:hover .completeCategoryImage {
  background-color: #c0dce2;
}

.completeCardCategory:hover {
  font-weight: 500;
}

.completeCategoryImage {
  background-color: #F2F5F6;
  border-radius: 8px;
  padding-block: 1rem;
  display: flex;
  align-items: center;
}

.categoryImage {
  padding-left: 1.5rem;
  height: 42px;
  width: 60px;
}
