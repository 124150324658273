.startBuyingButton{
  border: 1px solid #033858;
  color: #033858;
  font-weight: 500;
  border-radius: 100px;
  padding: 0.6rem 1.5rem;
  cursor: pointer;
}

.startBuyingButton:hover{
  color: #FFFF;
  background-color: #033858;
}

.orderIcons {
  margin-block: 1rem;
}

.orderIconsButtons:hover {
  background-color: #F2F5F6;
  font-weight: 500;
}

.orderIconsButtons {
  border: 1px solid #033859 !important;
  background-color: #fff;
  color: #033858;
  font-weight: 400;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0.1;
  white-space: nowrap;
  padding-block: 1.8rem;
  padding-inline: 2rem;
  min-width: 150px;
  cursor: pointer;
}

.orderIconsButtons svg {
  filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(1353%) hue-rotate(175deg)
    brightness(100%) contrast(98%);
}
