.approvedBtn {
    background-color: #033858;
    color: #f0f0f0;
    border: none;
    border-radius: 4px;
    padding-block: 0.5rem;
    padding-inline: 1.5rem;
    margin-block: 1rem;
  }
  
  .approvedBtnOk {
    background-color: #53bf9d;
  }
  
  .trackingBtn{
    background-color: #53BF9D;
    color: #f0f0f0;
    border: none;
    border-radius: 4px;
    padding-block: 0.5rem;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .approvedBtnDeny {
    background-color: #d61c4e;
  }
  
  .form-control{
    font-family: system-ui, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;
  }
  
  .formCheckInput input[type='radio']{
    accent-color: #033858;
    transform: translateY(0px);
  }
  
  .cardBubble input[type='radio']:hover{
    background-color: #033858;
    accent-color: #06619a;
  }
  
  .formCheckInput input[type='radio']:checked{
    appearance: auto;
    background-color: #033858;
    accent-color: #033858;
  }
  
  .trackingButton{
    background-color: #F2F5F6;
    border-radius: 4px;
    color: #033858;
    font-weight: 600;
    padding: 0.5rem 1rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .warningText{
    display: flex;
    font-size: 0.8rem;
    line-height: 14px;
    color: #4A4A4A;
  }

  .bottomText{
    font-size: 0.9rem;
    font-weight: 300;
  }

  .buyAgainContainer{
    display: flex;
    flex-direction: column;
    background-color: #F2F5F6;
    padding: 1rem 2rem;
    margin-top: 1.5rem;
    color: #033858;
    text-align: center;
  }

  .buyAgainButton{
    border: 1px solid #033858;
    border-radius: 8px;
    padding: 1rem;
    font-weight: 600;
  }