.contactBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #033858;
  padding-inline: 2rem;
}

.locationBubble{
  background-color: #ffff;
  width: max-content;
  border-radius: 16px;
  padding: 0.5rem 1.5rem;
  position: absolute;
  top: 2.5rem;
  left: -50px;
  z-index: 5;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.locationBubbleButton{
  padding: 1rem 2rem;
  color: #033858;
  font-weight: 500;
  background-color: #F2F5F6;
  border-radius: 8px;
}

.triangle {
  width: 0;
  height: 0;
  border: 32px solid transparent;
  border-top: 0;
  border-bottom: 39px solid #ffff;
  position: absolute;
  top: -16px;
  right: 194px;
}

.contactBar span {
  margin-bottom: 0px;
  color: #f0f0f0;
  font-size: 0.8rem;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
